<template>

  <div style="height: 100%;overflow: auto">
    <div>
      <div class="mining">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title">{{ $t('leftPage.采矿') }}</span>
          <img src="@/assets/imgs/my.png" alt="" class="App_icon_my" @click="goToHomePage('true')" />
        </div>

        <div class="topBanner">
          <div class="bannerTxt">{{ $t('miningPage.你值得信任的量化投資') }}</div>
          <div class="openAccount">
            <div class="openBtn"><span>{{ $t('miningPage.立即开户') }}</span></div>
          </div>
        </div>

        <div class="topBanner2">
          <div class="ad">{{ $t('miningPage.总流动性矿池金额') }}</div>
          <div class="ad">681,254.87<div class="unit"><span>USDT</span></div></div>
          <div class="ad">{{ $t('miningPage.质押人數') }}</div>
          <div class="ad">1,583</div>
        </div>

        <div class="page-content">
          <div class="menuTab">
            <div class="tabItem">
              <div id="mining_tabTxt1" class="tabTxt" @click="selectTab('mining_tabTxt1')"><span>{{ $t('miningPage.质押') }}</span></div>
            </div>
            <div class="tabItem">
              <div id="mining_tabTxt2" class="tabTxt" @click="selectTab('mining_tabTxt2')"><span>{{ $t('miningPage.质押记录') }}</span></div>
            </div>
            <div class="tabItem">
              <div id="mining_tabTxt3" class="tabTxt" @click="selectTab('mining_tabTxt3')"><span>{{ $t('miningPage.质押收益') }}</span></div>
            </div>
          </div>
          <div class="menuBodyBox">
            <!---->
            <div v-if="pledge" class="mining_menuBody">
              <div class="formTitle">{{ $t('miningPage.输入要质押的USDT数量') }}</div>
              <div class="fromInput">
                <input class="mining-input" readonly="readonly" @click="showNumKeyboardCom('mining')" type="text" :placeholder="$t('miningPage.质押总量')+'≥100'">
                <div class="maxBtn" @click="maxAmount"><span>{{ $t('miningPage.最大限度') }}</span></div>
              </div>
              <div class="mining_balanceBox">
                <div class="mining_balance">
<!--                  <div class="text-grey"><span>{{ $t('miningPage.余额') }}:</span></div>-->
<!--                  <div class="text-black"><span>{{amount}} USDT</span></div>-->
                </div>
                <div class="mining_walletBalance">
                  <div class="text-grey"><span>{{ $t('miningPage.钱包余额') }}：</span></div>
                  <div class="text-black"><span>{{amount}} USDT</span></div>
                </div>
              </div>
              <div class="incomeRate">
                <img src="@/assets/imgs/yield.png" alt="" />
                <div class="text-grey"><span>{{ $t('miningPage.收益率') }}：</span></div>
                <div class="text-black"><span>{{ incomeRate }}%</span></div>
              </div>
              <div class="incomeRate">
                <img src="@/assets/imgs/yield.png" alt="" />
                <div class="text-grey"><span>{{ $t('期限') }}：</span></div>
                <div class="text-black"><span>{{ period }} {{ $t('天') }}</span></div>
              </div>
              <div class="pledgeBox">
                <div class="pledgeTitle" @click="showProductPicker = true">
                  <div class="pledgeTitleTxt"><span>{{ $t('选择质押的产品') }}：{{ product }}</span></div>
                </div>
                <div style="width: 100%"></div>
                <div class="pledgeArea">
                  <div class="pledgeBtn" @click="miningSub">{{ $t('miningPage.质押') }}</div>
                </div>
              </div>
            </div>
            <!---->
            <div v-if="pledgeRecord" class="mining_menuBody">
              <div class="pledgeReport">
                <div class="pledgeReportItem">
                  <div class="reportTitle"><span>{{ $t('miningPage.当前总质押') }}</span></div>
                  <div class="reportValue"><span>{{ orderNum }}</span></div>
                </div>
                <div class="pledgeReportItem">
                  <div class="reportTitle"><span>{{ $t('miningPage.当日收益') }}</span></div>
                  <div class="reportValue"><span>{{ todayProfit }} USDT</span></div>
                </div>
                <div class="pledgeReportItem">
                  <div class="reportTitle"><span>{{ $t('miningPage.总收益') }}</span></div>
                  <div class="reportValue"><span>{{ totalProfit }} USDT</span></div>
                </div>
              </div>
              <div v-if="miningRecords.length===0" class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-500px + 100vh);">
                <img src="@/assets/imgs/noData-icon.png" alt="" class="mining_img_no_data" />
                <div class="no_data_text">{{ $t('miningPage.没有记录') }}</div>
              </div>
              <van-list v-model:loading="loading" :finished="finished" finished-text="Load complete"  @load="onLoad">
                <div v-if="miningRecords.length!==0" class="data_content ff_NunitoSemiBold" style="max-height: calc(-250px + 100vh);overflow: auto">
                  <div v-for="(record,index) in miningRecords" :key="index" class="orderList" >
                    <div class="textHeader">{{ $t('订单号') }}：<span>{{ record.order_no }}</span></div>
                    <div class="textBody">
                      <div>{{ $t('质押数量') }}：<span>{{ parseFloat(record.amount)  }}</span></div>
                      <div>{{ $t('期限') }}：<span>{{ record.period }}{{ $t('天') }}</span></div>
                      <div>{{ $t('开始时间') }}：<span>{{ record.start_time }}</span></div>
                      <div>{{ $t('结束时间') }}：<span>{{ record.end_time }}</span></div>
                      <div>{{ $t('收益率') }}：<span>{{ record.income_rate }}%</span></div>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
            <!---->
            <div v-if="pledgeEarnings" class="mining_menuBody">
              <div v-if="miningIncomeRecords.length===0" class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-550px + 100vh);">
                <img src="@/assets/imgs/noData-icon.png" alt="" class="mining_img_no_data" />
                <div class="no_data_text">{{ $t('miningPage.没有记录') }}</div>
              </div>
              <van-list v-model:loading="loading2" :finished="finished2" finished-text="Load complete"  @load="onLoad2">
                <div v-if="miningIncomeRecords.length!==0" class="data_content ff_NunitoSemiBold" style="max-height: calc(-250px + 100vh);overflow: auto">
                  <div v-for="(incomeRecord,index) in miningIncomeRecords" :key="index" class="orderList">
                    <div style="flex-direction: row !important;padding: 10px;">
                      <div style="padding-bottom: 10px">{{ $t('收益金额') }}：<span>{{ parseFloat(incomeRecord.amount)  }} {{ incomeRecord.unit }}</span></div>
                      <div>{{ $t('时间') }}：<span>{{ incomeRecord.ctime }}</span></div>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
          </div>
          <div class="incomeLogBox">
<!--            <div class="incomeLogHead">-->
<!--              <div class="incomeAddress">{{ $t('miningPage.挖矿收益地址') }}</div>-->
<!--              <div class="incomeAmount">{{ $t('miningPage.数量') }}</div>-->
<!--            </div>-->
<!--            <div class="incomeLogBody">-->
<!--              <div class="market-box">-->
<!--                <div class="uni-scroll-view">-->
<!--                  <div class="uni-scroll-view" style="overflow: hidden auto;">-->
<!--                    <div class="uni-scroll-view-content">-->
<!--                      &lt;!&ndash;&ndash;&gt;-->
<!--                      <div style="height: 100%; width: 100%;">-->
<!--                        <div class="uni-swiper-wrapper">-->
<!--                          <div class="uni-swiper-slides">-->
<!--                            <div class="uni-swiper-slide-frame" style="width: 100%; height: 20%;"></div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="qasBox">
            <div class="qasTitle">{{ $t('miningPage.相关问题') }}</div>
            <div class="qasListItem">
              <div class="qasItemTitle">1.{{ $t('miningPage.什麼是AI量化交易') }}？</div>
              <div class="qasContent">{{ $t('miningPage.文本1') }}</div>
            </div>
            <div class="qasListItem">
              <div class="qasItemTitle">2.{{ $t('miningPage.为什么要支付手续费') }}？</div>
              <div class="qasContent">{{ $t('miningPage.文本2') }}</div>
            </div>
            <div class="qasListItem">
              <div class="qasItemTitle">3.{{ $t('miningPage.AI量化交易的优势') }}</div>
              <div class="qasContent">
                1.{{ $t('miningPage.文本3_1') }}
                2.{{ $t('miningPage.文本3_2') }}
                3.{{ $t('miningPage.文本3_3') }}
                4.{{ $t('miningPage.文本3_4') }}
                5.{{ $t('miningPage.文本3_5') }}
                6.{{ $t('miningPage.文本3_6') }}
                7.{{ $t('miningPage.文本3_7') }}
                8.{{ $t('miningPage.文本3_8') }}</div>
            </div>
            <div class="qasListItem">
              <div class="qasItemTitle">4.{{ $t('miningPage.在哪里进行AI量化交易') }}？</div>
              <div class="qasContent">{{ $t('miningPage.文本4') }}</div>
            </div>
            <div class="qasListItem">
              <div class="qasItemTitle">5.{{ $t('miningPage.为什么会有这项目') }}？</div>
              <div class="qasContent">{{ $t('miningPage.文本5') }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
  <NumberKeyboard
      :showNumKeyboard="showNumKeyboard" :inputType="inputType" :availableAmount="amount"
      @update:showNumKeyboard="showNumKeyboard = $event"/>

  <van-popup v-model:show="showProductPicker" position="bottom">
    <van-picker :columns="productColumns" @cancel="showProductPicker = false" @confirm="onConfirmProduct"/>
  </van-popup>
</template>

<script setup>
import NumberKeyboard from '@/components/NumberKeyboard.vue';
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/mining.css';
import { useRouter } from "vue-router";
import {onMounted, ref} from "vue";
import {getTime, refresh} from "@/common/way";
import {
  financeOrder,
  getAccountUsdt, getBonusRecord,
  getMiningProduct,
  getMiningRecord,
  getMiningReport
} from "@/assets/js/api/interface";
import {showConfirmDialog, showNotify} from "vant";
import { useI18n } from "vue-i18n";

const router = useRouter();
const { t } = useI18n();
//usdt钱包余额
const amount = ref('0');
//数字键盘
const showNumKeyboard = ref(false);
const inputType = ref('');
//质押、质押记录、质押收益tab
const pledge = ref(true);
const pledgeRecord = ref(false);
const pledgeEarnings = ref(false);

//弹出数字键盘
function showNumKeyboardCom(type){
  showNumKeyboard.value = true;
  inputType.value = type;
}

//最大额度
function maxAmount(){
  let input = document.querySelector('.mining-input');
  input.value = amount.value;
}

function goToHomePage(bol){
  router.push('/home');
  localStorage.setItem('paramBol',bol);
}

//委托订单、最新交易
function selectTab(id) {
  document.getElementById('mining_tabTxt1').classList.remove('tab_active');
  document.getElementById('mining_tabTxt2').classList.remove('tab_active');
  document.getElementById('mining_tabTxt3').classList.remove('tab_active');
  pledge.value = false;
  pledgeRecord.value = false;
  pledgeEarnings.value = false;
  const item = document.getElementById(id);
  item.classList.add('tab_active');
  if (id==='mining_tabTxt1'){
    pledge.value = true;
    return;
  }
  if (id==='mining_tabTxt2'){
    pledgeRecord.value = true;
    return;
  }
  if (id==='mining_tabTxt3'){
    pledgeEarnings.value = true;
  }
}

//选择质押的产品
const showProductPicker = ref(false);
const productColumns = ref([]);
const incomeRates = ref({});
const productDat = ref({});
const product = ref('');
const productId = ref('');
const incomeRate = ref(0);
const period = ref(0);
function onConfirmProduct(event){
  showProductPicker.value = false;
  product.value = event.selectedOptions[0].text;
  productId.value = event.selectedOptions[0].value;
  incomeRate.value = incomeRates.value[productId.value];
  period.value = productDat.value[productId.value];
}

// 获取质押产品
const fundMiningProduct = async () => {
  try {
    const result = await getMiningProduct();
    for (let i = 0; i < result.data.length; i++) {
      productColumns.value.push({text:result.data[i].name,value:result.data[i].id})
      incomeRates.value[result.data[i].id] = result.data[i].income_rate;
      productDat.value[result.data[i].id] = result.data[i].period;
    }
  }catch (error) {
    console.error(error)
  }
}

//获取账户余额
const getUsdtWalletAmount = async () => {
  try {
    const data = await getAccountUsdt();
    amount.value = data.data.balance;
  }catch (error){
    console.error(error)
  }
}

//获取质押报告
const orderNum = ref(0);
const todayProfit = ref(0);
const totalProfit = ref(0);
const getMiningReports = async () => {
  const result = await getMiningReport();
  orderNum.value = result.data.order_sum;
  todayProfit.value = result.data.today_profit;
  totalProfit.value = result.data.aready_profit;
}

//质押提交
const miningSub = async ()=> {
  let miningAmount = document.querySelector('.mining-input');
  if (miningAmount.value==='' || parseFloat(miningAmount.value)===0){
    backMsg('质押金额必须大于0')
    return;
  }
  if (product.value===''){
    backMsg('请选择质押产品')
    return;
  }
  showConfirmDialog({
    message: t('coinDetailPage.确认要下单吗'),
    confirmButtonText: t('确认'),  // 修改确认按钮文字
    cancelButtonText: t('取消'),    // 修改取消按钮文字
  })
      .then(async () => {
        //提交
        let data = {
          financeId: productId.value,
          amount: parseFloat(miningAmount.value)
        }
        try {
          const result = await financeOrder(data);
          backMsgSuccess(result.msg);

          //获取账户余额
          await getUsdtWalletAmount();
          //获取质押报告
          await getMiningReports();
          //获取质押记录
          await getMiningRecords();
        } catch (error) {
          console.error(error)
        } finally {
          productId.value = '';
          product.value = '';
          incomeRate.value = 0;
          incomeRates.value = {};
          period.value = 0;
          productDat.value = {};
          miningAmount.value = '';
        }
      })
      .catch(() => {
        // on cancel
      });
}

//获取质押记录
let page = 1;
let size = 10;
const miningRecords = ref([]);
const getMiningRecords = async () => {
  try {
    const data = {
      page:page,
      size:size
    }
    const result = await getMiningRecord(data);
    if (result.data.length < 9){
      finished.value = true;
    }
    if (page>1){
      miningRecords.value.push(...result.data)
    }else {
      miningRecords.value = result.data;
    }
  }catch (error) {
    console.error(error)
  }
}

const loading = ref(false);
const finished  = ref(false);
let count = 0;
//进行滑动加载数据
const onLoad = async () =>{
  if (count>0){
    page+=1;
  }
  try {
    //获取质押记录
    await getMiningRecords();
  }catch (error) {
    console.error("数据获取失败:"+error)
  }finally {
    // 加载状态结束
    loading.value = false;
  }
}

//获取质押收益记录
let page2 = 1;
const miningIncomeRecords = ref([]);
const getMiningIncomeRecord = async () =>{
  const data = {
    page:page2,
    size:size,
  }
  try {
    const result = await getBonusRecord(data);
    if (result.data.length < 9){
      finished2.value = true;
    }
    if (page2>1){
      miningIncomeRecords.value.push(...result.data)
    }else {
      miningIncomeRecords.value = result.data;
    }
    for (let i = 0; i < miningIncomeRecords.value.length; i++) {
      console.log(miningIncomeRecords.value[i].ctime)
      miningIncomeRecords.value[i].ctime = getTime(miningIncomeRecords.value[i].ctime);
    }
  }catch (error) {
    console.error(error)
  }
}

const loading2 = ref(false);
const finished2  = ref(false);
let count2 = 0;
//进行滑动加载数据
const onLoad2 = async () =>{
  if (count2>0){
    page2+=1;
  }
  try {
    //获取质押收益记录
    getMiningIncomeRecord();
  }catch (error) {
    console.error("数据获取失败:"+error)
  }finally {
    // 加载状态结束
    loading.value = false;
  }
}

onMounted(()=>{
  document.getElementById('mining_tabTxt1').classList.add('tab_active');

  fundMiningProduct();// 获取质押产品

  //获取账户余额
  getUsdtWalletAmount();
  //获取质押报告
  getMiningReports();

  refresh();
})

function backMsgSuccess(msg){
  showNotify({
    message: msg,
    color: '#fff',
    background: 'green',
  });
}
function backMsg(msg){
  showNotify({
    message: t(msg),
    color: '#fff',
    background: 'red',
  });
}
</script>