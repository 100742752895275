<template>
    <div style="height: 100%;overflow: auto">
        <div>
            <div class="arbitrage_record">

                <div class="pages_header">
                    <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
                    <span class="App_header_title"> {{ $t('tradingPage.交易记录') }} </span>
                </div>

                <div class="switch_container">
                    <div class="switch_content">
                        <div class="switch_item" @click="switchToggle('receive')" v-if="switchReceive"><span>{{
                                $t('tradingPage.接收') }}</span></div>
                        <div class="switch_item active" @click="switchToggle('receive_active')"
                            v-if="switchReceiveActive"><span>{{ $t('tradingPage.接收') }}</span></div>

                        <div class="switch_item" @click="switchToggle('send')" v-if="switchSend"><span>{{
                                $t('tradingPage.发送') }}</span></div>
                        <div class="switch_item active" @click="switchToggle('send_active')" v-if="switchSendActive">
                            <span>{{ $t('tradingPage.发送') }}</span>
                        </div>

                        <div class="switch_item" @click="switchToggle('convert')" v-if="switchConvert"><span>{{
                                $t('tradingPage.转换') }}</span></div>
                        <div class="switch_item active" @click="switchToggle('convert_active')"
                            v-if="switchConvertActive"><span>{{ $t('tradingPage.转换') }}</span></div>
                    </div>
                </div>

                <div class="switch_content"></div>

                <div>
                    <div class="no_data_content ff_NunitoSemiBold" style="min-height: calc(-260px + 100vh);"
                        v-if="ArrList.length == 0">
                        <img src="@/assets/imgs/noData-icon.png" alt="" class="img_no_data" />
                        <div class="no_data_text">{{ $t('tradingPage.无数据') }}</div>
                    </div>

                    <div class="RecordList">
                        <van-list v-model:loading="loading" :finished="finished" @load="onLoad">
                            <div v-for="(item, index) in ArrList" :key="index" class="RecordRow">
                                <div class="Record_info">
                                    <div style="margin-bottom: 10px;">{{ $t("时间") }}</div>
                                    <div>{{ item.ctime }}</div>
                                </div>

                                <div class="Record_info">
                                    <div style="margin-bottom: 10px;">{{ $t("数量") }}</div>
                                    <div style="overflow-wrap: break-word">{{ parseFloat(item.amount) }}</div>
                                </div>

                                <div class="Record_info">
                                    <div style="margin-bottom: 10px;">{{ $t("状态") }}</div>
                                    <div v-if="item.status == 0">{{ $t("审核中") }}</div>
                                    <div v-if="item.status == 1">{{ $t("已完成") }}</div>
                                    <div v-if="item.status == 2">{{ $t("审核未通过") }}</div>
                                </div>
                            </div>

                        </van-list>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <ServiceComponents />
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/trading_record.css';
import { useRoute, useRouter } from "vue-router";
import {onMounted, ref} from "vue";
import { refresh } from "@/common/way";
import { GetRecordByType } from "@/assets/js/api/interface";

const router = useRouter();
const route = useRoute();
const switchReceive = ref(false);
const switchReceiveActive = ref(true);
const switchSend = ref(true);
const switchSendActive = ref(false);
const switchConvert = ref(true);
const switchConvertActive = ref(false);

const finished = ref(false);
const loading = ref(false)
const ArrList = ref([])
const id = route.params.id;
let Pageindex = 0;
const type = ref('recharge');

// 下拉数据
async function onLoad(){
    Pageindex++;
    await GetArrData();
    loading.value = false;
}

const currency_id = route.params.id;

// 获取数据
async function GetArrData(){
    const arr = await GetRecordByType({currency_id: currency_id, page: Pageindex, size: 6, type: type.value })
    if(arr.data){
        ArrList.value.push(...(arr.data))

        if (arr.data.length < 7) {
            finished.value = true; 
            loading.value = false;
        }
    }
}

function switchToggle(str){

  if (str === 'receive'){
    switchReceiveActive.value = true;
    switchReceive.value = false;
    switchSendActive.value = false;
    switchSend.value = true;
    switchConvertActive.value = false;
    switchConvert.value = true;
    type.value = 'recharge';
  }
  if (str === 'send'){
    switchSendActive.value = true;
    switchSend.value = false;
    switchReceiveActive.value = false;
    switchReceive.value = true;
    switchConvertActive.value = false;
    switchConvert.value = true;
    type.value = 'withdraw';
  }
  if (str === 'convert'){
    switchConvertActive.value = true;
    switchConvert.value = false;
    switchReceiveActive.value = false;
    switchReceive.value = true;
    switchSendActive.value = false;
    switchSend.value = true;
    type.value = 'exchange';
  }
    Pageindex = 0;
    ArrList.value = [];
    loading.value = false;
    finished.value = false;
  refresh();
}

onMounted(()=>{
  refresh();
})
</script>

<style scoped>

.van-list{
    display: flex;
    flex-direction: column;
    gap: 25px;
    box-sizing: border-box;
    padding: 0 15px;
    color: white;
}

.RecordRow{
    width: 100%;
    border: 1px solid white;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.Record_info{
    width: 30%;
}
</style>