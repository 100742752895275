export default {
    homePage:{
        '开始赚钱计划':'Iniciar Plan de Ganancias',
        '快速进入':'Acceso Rápido',
        '市场':'Mercado',
        '数字货币':'Criptomoneda',
        '涨幅':'Incremento',
        '邀请朋友':'Invitar Amigos',
        '邀请朋友加入':'Invitar Amigos a Unirse',
        '开始邀请':'Comenzar a Invitar',
        '查看':'Ver',
        'Ai智能套利':'Arbitraje Inteligente AI',
        '200家交易所的智能交易':'Comercio Inteligente en 200 Exchanges',
        '矿机租赁':'Renta de Mineros',
        '效率高,回报快':'Alta Eficiencia, Rápida Rentabilidad'
    },

    languagesPage:{
        '语言':'Idioma',
        '确认':'Confirmar'
    },

    noticeRecord:{
        '通知':'Notificaciones',
        '消息':'Mensajes',
        '无数据':'Sin Datos',
    },

    leftPage:{
        '钱包':'Cartera',
        '套利':'Arbitraje',
        '采矿':'Minería',
        '设置':'Configuraciones',
    },

    accountPage:{
        '账户':'Cuenta',
        '立即发送加密货币':'Enviar Criptomoneda Ahora',
        '选择一个钱包来发送加密货币':'Seleccionar una Cartera para Enviar Criptomoneda',
        '选择一个钱包':'Seleccionar una Cartera',
        '搜索':'Buscar',
        '钱包':'Cartera',
    },

    walletPage:{
        "钱包": "Cartera",
        "可用": "Disponible",
        "冷冻": "Congelado",
        "接收": "Recibir",
        "发送": "Enviar",
        "转换": "Convertir",
        "存入资金": "Depositar Fondos",
        "退出": "Cerrar sesión",
        "最大": "Máx",
        "立即发送": "Enviar Ahora",
        "从": "Desde",
        "到": "Hasta",
        "费用": "Tarifa",
        "继续固定": "Continuar Fijo",
        "内容1": "Por favor, no envíe otros tipos de activos a la dirección anterior. Esta operación puede resultar en la pérdida de sus activos. Después del envío exitoso, los nodos de la red necesitan confirmar la recepción de los activos correspondientes. Por lo tanto, comuníquese con el servicio al cliente en línea a tiempo para verificar la recepción cuando complete la transferencia.",
        "内容2": "Por favor, no transfiera fondos a extraños",
        "内容3": "No puede intercambiar directamente entre dos criptomonedas. Primero debe convertir una criptomoneda a USDT y luego usar USDT para intercambiar por cualquier otra criptomoneda.",
        "资金密码": "Contraseña de Fondos",
        "请输入资金密码": "Por favor, ingrese la contraseña de fondos",
        "未设置资金密码": "Contraseña de fondos no establecida",
        "发送地址不能为空": "La dirección de envío no puede estar vacía",
        "金额必须大于0": "El monto debe ser mayor que 0"
    },

    tradingPage:{
        '交易记录':'Registros de Comercio',
        '接收':'Recibir',
        '发送':'Enviar',
        '转换':'Convertir',
        '无数据':'Sin Datos',
    },

    arbitragePage:{
        '主持工作':'Arbitraje',
        '总套利':'Arbitraje Total',
        '今日收益':'Ganancia de Hoy',
        '托管订单':'Órdenes de Custodia',
        '介绍':'Introducción',
        '人工智能机器人是如何工作的':'Cómo Funciona el Robot de IA',
        '套利产品':'Productos de Arbitraje',
        'AI智能挖矿':'Minería Inteligente AI',
        '低买高卖获利':'Comprar Bajo y Vender Alto para Ganar',
    },

    trusteeshipDetailPage:{
        '托管详细':'Detalles de Custodia',
        '主持':'Arbitraje',
        '终止':'Finalizar',
        '赎回':'Redimir',
        '无数据':'Sin Datos',
    },

    miningPage:{
        '你值得信任的量化投資':'Inversión Cuantitativa en la que Puedes Confiar',
        '立即开户':'Abrir Cuenta Ahora',
        '总流动性矿池金额':'Monto Total del Fondo de Liquidez',
        '质押人數':'Número de Stakers',
        '质押':'Staking',
        '质押记录':'Registros de Staking',
        '质押收益':'Ganancias de Staking',
        '输入要质押的USDT数量':'Ingrese la Cantidad de USDT para Staking',
        '质押总量':'Cantidad Total de Staking',
        '最大限度':'Límite Máximo',
        '余额':'Saldo',
        '钱包余额':'Saldo de la Cartera',
        '收益率':'Tasa de Rendimiento',
        '选择质押的期限':'Seleccionar Duración del Staking',
        '没有记录':'Sin Registros',
        '挖矿收益地址':'Dirección de Ganancias de Minería',
        '数量':'Cantidad',
        '相关问题':'Preguntas Frecuentes',
        '什麼是AI量化交易':'¿Qué es el Comercio Cuantitativo AI?',
        '文本1':'El comercio cuantitativo es un enfoque que enfatiza la enseñanza y el análisis estadístico para ayudar a determinar el valor de los activos financieros (como acciones u opciones, criptomonedas). Los comerciantes cuantitativos utilizan diversos datos, incluidos datos históricos de inversiones y del mercado de valores, para desarrollar algoritmos comerciales y modelos computacionales. Esta información ayuda a los inversores a analizar oportunidades de inversión y desarrollar estrategias comerciales exitosas. Generalmente, la estrategia de comercio incluirá información muy específica sobre puntos de entrada y salida, riesgos esperados y rendimientos esperados. El objetivo final del análisis cuantitativo financiero es usar estadísticas e indicadores cuantificables para ayudar a los inversores a tomar decisiones de inversión rentables.',
        '为什么要支付手续费':'¿Por qué pagar tarifas?',
        '文本2':'Debido a que necesita abrir una cuenta para la inversión en comercio cuantitativo, se cobra ETH como tarifa de apertura de cuenta.',
        'AI量化交易的优势':'Ventajas del Comercio Cuantitativo AI',
        '文本3_1':'Sin intervención humana',
        '文本3_2':'El comercio cuantitativo AI reduce errores cognitivos',
        '文本3_3':'Las estrategias de comercio cuantitativo AI duplican los resultados',
        '文本3_4':'Las estrategias cuantitativas AI permiten pruebas retrospectivas',
        '文本3_5':'Se pueden implementar una cantidad ilimitada de estrategias',
        '文本3_6':'El comercio automatizado libera el tiempo de los inversores',
        '文本3_7':'Los sistemas de comercio cuantitativo muestran consistencia entre la ejecución y la prueba retrospectiva',
        '文本3_8':'El sistema ayuda a seguir su plan',
        '在哪里进行AI量化交易':'¿Dónde se realiza el comercio cuantitativo AI?',
        '文本4':'Comercio cuantitativo en colaboración con Coinbase, todos los programas se ejecutan en la cartera de Coinbase, es un proyecto de comercio cuantitativo AI transparente y justo.',
        '为什么会有这项目':'¿Por qué existe este proyecto?',
        '文本5':'En 2022, colaboramos oficialmente con Coinbase para construir un nuevo círculo de consenso de AI+Finanzas. Junto con Coinbase, hemos invertido 2 mil millones de dólares para desarrollar una plataforma de comercio cuantitativo que logra una integración perfecta entre AI y finanzas. El producto principal es el chip de análisis AI, integrado en nuestro último modelo de robot AI 6.2.1. Su lanzamiento ha roto y desafiado las tradiciones de múltiples industrias, incluida la tradicional transacción financiera. La plataforma de comercio cuantitativo hace que el comercio sea más fácil. En el siguiente paso, como una nueva generación de plataforma de comercio de activos criptográficos, seguiremos avanzando y lanzaremos con fuerza la versión Web3.0.',
        '当前总质押':'Monto Total de Staking Actual',
        '当日收益':'Ganancia del Día',
        '总收益':'Ganancia Total',
    },

    inviteFriendsPage:{
        '邀请朋友':'Invitar Amigos',
        '邀请朋友获得推荐奖励':'Invitar Amigos para Obtener Recompensas de Recomendación',
        '好友参与即可获得推荐奖励':'Amigos que participen en arbitraje AI o en renta de mineros recibirán recompensas de recomendación',
        '您的邀请成功':'Su invitación fue exitosa',
        '全部':'Todo',
        '直接':'Directo',
        '间接':'Indirecto',
        '更多':'Más',
        '直接收益':'Directas',
        '间接收益':'Indirectas',
        '无数据':'Sin Datos',
        '复制成功':'Copiado Exitosamente',
    },

    coinDetailPage:{
        '交割合约':'Contrato de Futuros',
        '最低价':'Precio Mínimo',
        '成交量':'Volumen de Comercio',
        '最高价':'Precio Máximo',
        '成交额':'Valor del Comercio',
        '时间':'Tiempo',
        '更多':'Más',
        '委托订单':'Órdenes',
        '最新交易':'Transacciones Más Recientes',
        '暂无数据':'Datos No Disponibles',
        '记录':'Registros',
        '买涨':'Comprar al Alza',
        '买跌':'Comprar a la Baja',
        '交割':'Liquidación',
        '交割时间':'Tiempo de Liquidación',
        '投资金额':'Monto de Inversión',
        '金额':'Monto',
        '余额':'Saldo',
        '预期收益':'Rendimiento Esperado',
        '确认下单':'Confirmar Pedido',
        '请输入投资金额':'Ingrese el Monto de Inversión',
        '确认要下单吗':'¿Confirmar el Pedido?',
        '余额不足':'Saldo Insuficiente',
    },

    contractsOrderPage:{
        '委托订单':'Órdenes',
        '等待中':'Esperando',
        '已結束':'Finalizado',
        '无数据':'Sin Datos',
    },

    numKeyboard:{
        '完成':'Hecho',
        '输入金额不能超过可用金额':'El monto ingresado no puede exceder el saldo disponible',
    },

    settingPage:{
        "设置": "Configuración",
        "修改资金密码": "Cambiar Contraseña de Fondos",
        "旧密码": "Contraseña Anterior",
        "请输入旧密码": "Por favor, ingrese la contraseña anterior",
        "新密码": "Nueva Contraseña",
        "请输入新密码": "Por favor, ingrese la nueva contraseña",
        "确认密码": "Confirmar Contraseña",
        "请再次输入新密码": "Por favor, ingrese la nueva contraseña nuevamente",
        "密码不能为空": "La contraseña no puede estar vacía",
        "新密码和确认密码不匹配": "La nueva contraseña y la confirmación de contraseña no coinciden",
        "取消": "Cancelar",
        "确认": "Confirmar",
        "设置资金密码": "Establecer Contraseña de Fondos",
        "资金密码": "Contraseña de Fondos"
    },

    interfaceJs: {
        "获取失败": "Error al recuperar",
        "登录失败": "Error de inicio de sesión",
        "设置成功": "Configuración guardada con éxito",
        "修改成功": "Modificación exitosa",
        "添加失败": "Error al agregar",
        "修改失败": "Error al modificar"
    },

    "时间": "Tiempo",
    "数量": "Cantidad",
    "状态": "Estado",
    "审核中": "En Revisión",
    "已完成": "Completado",
    "审核未通过": "Revisión Fallida",
    "购买金额": "Monto de Compra",
    "全部": "Todos",
    "输入金额": "Ingresar Monto",
    "可用余额": "Saldo Disponible",
    "大于最大可投": "Mayor que Máxima Inversión",
    "购买": "Comprar",
    "购买成功": "Compra Exitosa",
    "请输入购买金额": "Por Favor Ingrese Monto de Compra",
    "订单号": "Número de Pedido",
    "购买日期": "Fecha de Compra",
    "结束日期": "Fecha de Finalización",
    "预计收益": "Beneficio Esperado",
    "日收益率": "Tasa de Rendimiento Diario",
    "取消托管": "Cancelar Custodia",
    "接收金额": "Monto Recibido",
    "请填写金额": "Por Favor Ingrese Monto",
    "上传充值凭证": "Subir Comprobante de Recarga",
    "充值": "Recargar",
    "请上传图片": "Por Favor Suba Imagen",
    "请输入金额": "Por Favor Ingrese Monto",
    "确认": "Confirmar",
    "产品": "Producto",
    "购买数量": "Cantidad de Compra",
    "购买方向": "Dirección de Compra",
    "开仓时间": "Hora de Apertura",
    "平仓时间": "Hora de Cierre",
    "盈亏": "Ganancia y Pérdida",
    "取消": "Cancelar",
    "质押数量": "Cantidad de Prenda",
    "期限": "Plazo",
    "开始时间": "Hora de Inicio",
    "结束时间": "Hora de Fin",
    "收益率": "Tasa de Rendimiento",
    "天": "Días",
    "质押金额必须大于0": "El Monto de Prenda Debe Ser Mayor a 0",
    "请选择质押产品": "Por Favor Seleccione Producto de Prenda",
    "选择质押的产品": "Seleccionar Producto Pignorado",
    "限额": "Límite",
    "周期": "Ciclo",
    "预警收益": "Beneficio de Alerta",
    "数量限制": "Límite de Cantidad",
    "最少可投": "Mínimo Invertible",
    "最大可投": "Máximo Invertible",
    "立即租用": "Alquilar Ahora",
    "详情": "Detalles",
    "收益金额": "Monto de Ingresos",

    "身份认证": "Autenticación de Identidad",
    "个人身份认证": "Autenticación de Identidad Personal",
    "完成认证后可获得的权限": "Permisos disponibles tras completar",
    "权限一": "Aumentar el límite de retiro, límite de 500 BTC por 24 horas",
    "权限二": "Aumentar el límite de transacciones fiat, 1.000.000 USDT por transacción",
    "去认证": "Ir a la Autenticación",
    "已认证": "Certificado",
    "证件类型": "Tipo de Documento",
    "身份证": "Tarjeta de Identidad",
    "姓名": "Nombre",
    "证件号码": "Número de Documento",
    "上传证件正面": "Subir Frente del Documento",
    "正面证件照": "Foto del Frente del Documento",
    "上传证件反面": "Subir Espalda del Documento",
    "反面证件照": "Foto del Reverso del Documento",
    "确定": "Confirmar",
    "请输入姓名": "Por favor, ingrese su nombre",
    "请输入证件号码": "Por favor, ingrese su número de documento",
    "请上传证件": "Por favor, suba el documento",
    "查看认证信息": "Ver información de certificación",
}
