export default {
    homePage:{
        '开始赚钱计划':'Démarrer le Plan de Gains',
        '快速进入':'Accès Rapide',
        '市场':'Marché',
        '数字货币':'Cryptomonnaie',
        '涨幅':'Augmentation',
        '邀请朋友':'Inviter des Amis',
        '邀请朋友加入':'Inviter des Amis à Rejoindre',
        '开始邀请':'Commencer à Inviter',
        '查看':'Voir',
        'Ai智能套利':'Arbitrage Intelligent AI',
        '200家交易所的智能交易':'Commerce Intelligent sur 200 Bourses',
        '矿机租赁':'Location de Mineurs',
        '效率高,回报快':'Haute Efficacité, Rentabilité Rapide'
    },

    languagesPage:{
        '语言':'Langue',
        '确认':'Confirmer'
    },

    noticeRecord:{
        '通知':'Notifications',
        '消息':'Messages',
        '无数据':'Aucune Donnée',
    },

    leftPage:{
        '钱包':'Portefeuille',
        '套利':'Arbitrage',
        '采矿':'Minerai',
        '设置':'Paramètres',
    },

    accountPage:{
        '账户':'Compte',
        '立即发送加密货币':'Envoyer des Cryptomonnaies Maintenant',
        '选择一个钱包来发送加密货币':'Sélection un Portefeuille pour Envoyer des Cryptomonnaies',
        '选择一个钱包':'Sélection',
        '搜索':'Rechercher',
        '钱包':'Portefeuille',
    },

    walletPage:{
        "钱包": "Portefeuille",
        "可用": "Disponible",
        "冷冻": "Gelé",
        "接收": "Recevoir",
        "发送": "Envoyer",
        "转换": "Convertir",
        "存入资金": "Déposer des Fonds",
        "退出": "Se Déconnecter",
        "最大": "Max",
        "立即发送": "Envoyer Maintenant",
        "从": "De",
        "到": "À",
        "费用": "Frais",
        "继续固定": "Continuer Fixe",
        "内容1": "Veuillez ne pas envoyer d'autres types d'actifs à l'adresse ci-dessus. Cette opération peut entraîner la perte de vos actifs. Après l'envoi réussi, les nœuds du réseau doivent confirmer la réception des actifs correspondants. Par conséquent, veuillez contacter le service client en ligne à temps pour vérifier la réception lorsque vous avez terminé le transfert.",
        "内容2": "Veuillez ne pas transférer des fonds à des inconnus",
        "内容3": "Vous ne pouvez pas échanger directement entre deux cryptomonnaies. Vous devez d'abord convertir une cryptomonnaie en USDT, puis utiliser USDT pour échanger contre toute autre cryptomonnaie.",
        "资金密码": "Mot de Passe des Fonds",
        "请输入资金密码": "Veuillez entrer le mot de passe des fonds",
        "未设置资金密码": "Mot de passe des fonds non défini",
        "发送地址不能为空": "L'adresse d'envoi ne peut pas être vide",
        "金额必须大于0": "Le montant doit être supérieur à 0"
    },

    tradingPage:{
        '交易记录':'Historique des Transactions',
        '接收':'Recevoir',
        '发送':'Envoyer',
        '转换':'Convertir',
        '无数据':'Aucune Donnée',
    },

    arbitragePage:{
        '主持工作':'Arbitrage',
        '总套利':'Arbitrage Total',
        '今日收益':'Gain du Jour',
        '托管订单':'Ordres de Garde',
        '介绍':'Introduction',
        '人工智能机器人是如何工作的':'Comment Fonctionne le Robot IA',
        '套利产品':'Produits d’Arbitrage',
        'AI智能挖矿':'Minage Intelligent IA',
        '低买高卖获利':'Acheter Bas et Vendre Haut pour Gagner',
    },

    trusteeshipDetailPage:{
        '托管详细':'Détails de la Garde',
        '主持':'Arbitrage',
        '终止':'Terminer',
        '赎回':'Racheter',
        '无数据':'Aucune Donnée',
    },

    miningPage:{
        '你值得信任的量化投資':'Investissement Quantitatif en lequel Vous Pouvez Avoir Confiance',
        '立即开户':'dépositaire',
        '总流动性矿池金额':'Montant Total du Pool de Liquidité',
        '质押人數':'Nombre de Stakers',
        '质押':'Staking',
        '质押记录':'Historique de Staking',
        '质押收益':'Revenus de Staking',
        '输入要质押的USDT数量':'Entrer le Montant de USDT à Staker',
        '质押总量':'Montant Total Staké',
        '最大限度':'Limite Maximale',
        '余额':'Solde',
        '钱包余额':'Solde du Portefeuille',
        '收益率':'Taux de Rendement',
        '选择质押的期限':'Choisir la Durée du Staking',
        '没有记录':'Aucun Enregistrement',
        '挖矿收益地址':'Adresse des Revenus de Minage',
        '数量':'Quantité',
        '相关问题':'Questions Fréquemment Posées',
        '什麼是AI量化交易':'Qu’est-ce que le Commerce Quantitatif IA ?',
        '文本1':'Le commerce quantitatif est une approche qui met l’accent sur l’enseignement et l’analyse statistique pour aider à déterminer la valeur des actifs financiers (comme les actions ou les options, les cryptomonnaies). Les commerçants quantitatifs utilisent divers données, y compris des données historiques d’investissements et du marché boursier, pour développer des algorithmes de commerce et des modèles informatiques. Ces modèles informatiques fournissent des informations pour aider les investisseurs à analyser les opportunités d’investissement et à développer des stratégies commerciales réussies. En général, la stratégie de commerce comprendra des informations très spécifiques sur les points d’entrée et de sortie, les risques et les rendements attendus. L’objectif final de l’analyse financière quantitative est d’utiliser des statistiques et des indicateurs quantifiables pour aider les investisseurs à prendre des décisions d’investissement rentables.',
        '为什么要支付手续费':'Pourquoi Payer des Frais ?',
        '文本2':'Comme vous devez ouvrir un compte pour l’investissement quantitatif, les frais en ETH sont perçus comme frais d’ouverture de compte.',
        'AI量化交易的优势':'Avantages du Commerce Quantitatif IA',
        '文本3_1':'Sans intervention humaine',
        '文本3_2':'Le commerce quantitatif IA réduit les erreurs cognitives',
        '文本3_3':'Les stratégies de commerce quantitatif IA doublent les résultats',
        '文本3_4':'Les stratégies quantitatif IA permettent des tests rétrospectifs',
        '文本3_5':'Un nombre illimité de stratégies peut être mises en œuvre',
        '文本3_6':'Le commerce automatisé libère du temps pour les investisseurs',
        '文本3_7':'Les systèmes de commerce quantitatif montrent une cohérence entre l’exécution et les tests rétrospectifs',
        '文本3_8':'Le système aide à suivre votre plan',
        '在哪里进行AI量化交易':'Où Réaliser le Commerce Quantitatif IA ?',
        '文本4':'Commerce quantitatif en collaboration avec Coinbase, tous les programmes sont exécutés dans le portefeuille Coinbase, c’est un projet de commerce quantitatif IA transparent et juste.',
        '为什么会有这项目':'Pourquoi ce Projet Existe-t-il ?',
        '文本5':'En 2022, nous avons officiellement collaboré avec Coinbase pour créer un nouveau cercle de consensus AI+Finance. Avec Coinbase, nous avons investi 2 milliards de dollars pour développer une plateforme de commerce quantitatif qui réalise une intégration parfaite entre IA et finance. Le produit principal est le puce d’analyse IA, intégrée dans notre dernier modèle de robot IA 6.2.1. Son lancement a brisé et défié les traditions de plusieurs industries, y compris la transaction financière traditionnelle. La plateforme de commerce quantitatif rend le commerce plus facile. À l’étape suivante, en tant que nouvelle génération de plateforme de commerce d’actifs cryptographiques, nous continuerons à avancer et lancerons la version Web3.0.',
        '当前总质押':'Montant Total du Staking Actuel',
        '当日收益':'Revenu du Jour',
        '总收益':'Revenu Total',
    },

    inviteFriendsPage:{
        '邀请朋友':'Inviter des Amis',
        '邀请朋友获得推荐奖励':'Inviter des Amis pour Recevoir des Récompenses de Recommandation',
        '好友参与即可获得推荐奖励':'Les amis participant à l’arbitrage IA ou à la location de mineurs recevront des récompenses de recommandation',
        '您的邀请成功':'Votre Invitation a Réussi',
        '全部':'Tout',
        '直接':'Direct',
        '间接':'Indirect',
        '更多':'Plus',
        '直接收益':'Revenu Direct',
        '间接收益':'Revenu Indirect',
        '无数据':'Aucune Donnée',
        '复制成功':'Copie Réussie',
    },

    coinDetailPage:{
        '交割合约':'Contrat à Terme',
        '最低价':'Prix Minimum',
        '成交量':'Volume de Transactions',
        '最高价':'Prix Maximum',
        '成交额':'Montant des Transactions',
        '时间':'Temps',
        '更多':'Plus',
        '委托订单':'Ordres',
        '最新交易':'Transactions Récentes',
        '暂无数据':'Aucune Donnée',
        '记录':'Registres',
        '买涨':'Acheter à la Hausse',
        '买跌':'Acheter à la Baisse',
        '交割':'Liquidation',
        '交割时间':'Heure de Liquidation',
        '投资金额':'Montant Investi',
        '金额':'Montant',
        '余额':'Solde',
        '预期收益':'Revenu Attendu',
        '确认下单':'Confirmer la Commande',
        '请输入投资金额':'Veuillez Entrer le Montant à Investir',
        '确认要下单吗':'Confirmer la Commande ?',
        '余额不足':'Solde Insuffisant',
    },

    contractsOrderPage:{
        '委托订单':'Ordres',
        '等待中':'En Attente',
        '已結束':'Terminé',
        '无数据':'Aucune Donnée',
    },

    numKeyboard:{
        '完成':'Terminé',
        '输入金额不能超过可用金额':'Le montant saisi ne peut pas dépasser le solde disponible',
    },

    settingPage:{
        "设置": "Paramètres",
        "修改资金密码": "Modifier le Mot de Passe des Fonds",
        "旧密码": "Ancien Mot de Passe",
        "请输入旧密码": "Veuillez entrer l'ancien mot de passe",
        "新密码": "Nouveau Mot de Passe",
        "请输入新密码": "Veuillez entrer le nouveau mot de passe",
        "确认密码": "Confirmer le Mot de Passe",
        "请再次输入新密码": "Veuillez entrer à nouveau le nouveau mot de passe",
        "密码不能为空": "Le mot de passe ne peut pas être vide",
        "新密码和确认密码不匹配": "Le nouveau mot de passe et la confirmation du mot de passe ne correspondent pas",
        "取消": "Annuler",
        "确认": "Confirmer",
        "设置资金密码": "Définir le Mot de Passe des Fonds",
        "资金密码": "Mot de Passe des Fonds"
    },

    interfaceJs: {
        "获取失败": "Échec de la récupération",
        "登录失败": "Échec de la connexion",
        "设置成功": "Paramètres enregistrés avec succès",
        "修改成功": "Modification réussie",
        "添加失败": "Échec de l'ajout",
        "修改失败": "Échec de la modification"
    },

    "时间": "Temps",
    "数量": "Quantité",
    "状态": "Statut",
    "审核中": "En Révision",
    "已完成": "Terminé",
    "审核未通过": "Révision Échouée",
    "购买金额": "Montant d'Achat",
    "全部": "Tout",
    "输入金额": "Saisir Montant",
    "可用余额": "Solde Disponible",
    "大于最大可投": "Supérieur au Maximum d'Investissement",
    "购买": "Acheter",
    "购买成功": "Achat Réussi",
    "请输入购买金额": "Veuillez Saisir le Montant d'Achat",
    "订单号": "Numéro de Commande",
    "购买日期": "Date d'Achat",
    "结束日期": "Date de Fin",
    "预计收益": "Profit Prévu",
    "日收益率": "Taux de Rendement Quotidien",
    "取消托管": "Annuler la Garde",
    "接收金额": "Montant Reçu",
    "请填写金额": "Veuillez Saisir le Montant",
    "上传充值凭证": "Télécharger Preuve de Recharge",
    "充值": "Recharger",
    "请上传图片": "Veuillez Télécharger une Image",
    "请输入金额": "Veuillez Saisir le Montant",
    "确认": "Confirmer",
    "产品": "Produit",
    "购买数量": "Quantité d'Achat",
    "购买方向": "Direction d'Achat",
    "开仓时间": "Heure d'Ouverture",
    "平仓时间": "Heure de Clôture",
    "盈亏": "Profit et Perte",
    "取消": "Annuler",
    "质押数量": "Quantité de Gage",
    "期限": "Durée",
    "开始时间": "Heure de Début",
    "结束时间": "Heure de Fin",
    "收益率": "Taux de Rendement",
    "天": "Jours",
    "质押金额必须大于0": "Le Montant du Gage Doit Être Supérieur à 0",
    "请选择质押产品": "Veuillez Sélectionner le Produit de Gage",
    "选择质押的产品": "Sélectionner Produit Pignéré",
    "限额": "Plafond",
    "周期": "Cycle",
    "预警收益": "Profit d'Alerte",
    "数量限制": "Limite de Quantité",
    "最少可投": "Investissement Minimum",
    "最大可投": "Investissement Maximum",
    "立即租用": "Louer Maintenant",
    "详情": "Détails",
    "收益金额": "Montant des Revenus",

    "身份认证": "Authentification d'Identité",
    "个人身份认证": "Authentification d'Identité Personnelle",
    "完成认证后可获得的权限": "Permissions disponibles après achèvement",
    "权限一": "Augmenter la limite de retrait, limite de 500 BTC par 24 heures",
    "权限二": "Augmenter la limite de trading en fiat, 1.000.000 USDT par transaction",
    "去认证": "Aller à l'Authentification",
    "已认证": "Certifié",
    "证件类型": "Type de Document",
    "身份证": "Carte d'Identité",
    "姓名": "Nom",
    "证件号码": "Numéro de Document",
    "上传证件正面": "Télécharger le Recto du Document",
    "正面证件照": "Photo du Recto du Document",
    "上传证件反面": "Télécharger le Verso du Document",
    "反面证件照": "Photo du Verso du Document",
    "确定": "Confirmer",
    "请输入姓名": "Veuillez entrer votre nom",
    "请输入证件号码": "Veuillez entrer votre numéro de document",
    "请上传证件": "Veuillez télécharger le document",
    "查看认证信息": "Voir les informations de certification",
}
