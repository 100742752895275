<template>
    <div style="height: 100%;overflow: auto">
        <div>
            <div class="coinDetail" style="padding-bottom: 20vw">

                <div class="pages_header">
                    <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
                    <span class="App_header_title" @click="$router.go(-1)">
                        <img src="@/assets/imgs/convert.png" class="coin_detail-img" alt="" /> {{ (coinData.symbol) }}/USDT
                    </span>
                </div>

                <div class="coinInfo">
                    <div class="tradeType">{{ $t('coinDetailPage.交割合约') }}</div>
                    <div class="coinInfo_box">
                        <div class="coin_priceInfo">
                            <div class="priceValue fall">$ {{ Number(coinData.price) }}</div>
                            <div class="changeValue fall">
                                <div class="priceZf"
                                    :style="parseFloat(coinData.increase) > 0 ? { color: '#35fcd9' } : { color: '#dd4b4b' }"
                                >
                                    <span>{{ coinData.increase }}%</span>
                                </div>
                            </div>
                        </div>
                        <div class="coin_baseInfo">
                            <div class="baseInfoItem">
                                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.最低价') }}</div>
                                <div class="baseInfoItemValue">{{ Number(coinData.low) }}</div>
                            </div>
                            <div class="baseInfoItem">
                                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.成交量') }}({{coinData.symbol2}})
                                </div>
                                <div class="baseInfoItemValue">{{ Number(coinData.amount) }}</div>
                            </div>
                            <div class="baseInfoItem">
                                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.最高价') }}</div>
                                <div class="baseInfoItemValue">{{ Number(coinData.high) }}</div>
                            </div>
                            <div class="baseInfoItem">
                                <div class="baseInfoItemTitle">24H{{ $t('coinDetailPage.成交额') }}(USDT)</div>
                                <div class="baseInfoItemValue">{{ Number(coinData.vol) }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--K LINE FIGURE-->
                <div class="v-kline-chart-wraps bg-theme">
                    <div class="option-kline-container">
                        <div class="kline-tabs-wrap">
                            <div class="kline-tab-item" style="color: darkgray">
                                <div class="kline-tab-label">{{ $t('coinDetailPage.时间') }}</div>
                            </div>
                            <div class="kline-tab-item" @click="selectTime('1M')" :class="time == '1M' ? 'active' : ''">
                                <div class="kline-tab-label">1m</div>
                            </div>
                            <div class="kline-tab-item" @click="selectTime('5M')" :class="time == '5M' ? 'active' : ''">
                                <div class="kline-tab-label">5m</div>
                            </div>
                            <div class="kline-tab-item" @click="selectTime('30M')"
                                :class="time == '30M' ? 'active' : ''">
                                <div class="kline-tab-label">30m</div>
                            </div>
                            <div class="kline-tab-item" @click="selectTime('1H')" :class="time == '1H' ? 'active' : ''">
                                <div class="kline-tab-label">1h</div>
                            </div>
                            <div class="kline-tab-item" @click="selectTime('more')">
                                <div class="kline-tab-label">{{ $t('coinDetailPage.更多') }}</div>
                            </div>
                            <!--点击更多弹出选项-->
                            <div class="kline-tab-more-wrap bg-theme" v-if="showTabMore">
                                <div class="kline-tab-more-item" @click="selectTime('1D')"
                                    :class="time == '1D' ? 'active' : ''">
                                    <div class="kline-tab-more-label"><span>1D</span></div>
                                </div>
                                <div class="kline-tab-more-item" @click="selectTime('1W')"
                                    :class="time == '1W' ? 'active' : ''">
                                    <div class="kline-tab-more-label"><span>1W</span></div>
                                </div>
                                <div class="kline-tab-more-item" @click="selectTime('1MON')"
                                    :class="time == '1MON' ? 'active' : ''">
                                    <div class="kline-tab-more-label"><span>1M</span></div>
                                </div>
                            </div>
                        </div>
                        <!--K LINE-->
                        <div id="tv_chart_container" class="tv_chart_container" v-loading="loading"
                            element-loading-background="#012657"></div>
                    </div>
                </div>

                <div class="menuTab">
                    <div class="tabItem">
                        <div :class="tabType == 'hold' ? 'tab_active' : ''" class="tabTxt" @click="selectTab('hold')">
                            <span>
                                {{ $t('coinDetailPage.委托订单') }}
                            </span>
                        </div>

                    </div>
                    <div class="tabItem">
                        <div :class="tabType == 'history' ? 'tab_active' : ''" class="tabTxt"
                            @click="selectTab('history')">
                            <span>
                                {{ $t('coinDetailPage.最新交易') }}
                            </span>
                        </div>
                    </div>
                </div>

                <!--DATA-->
                <div class="menuBody">
                    <div v-if="orderList.length===0" class="no_data_content ff_NunitoSemiBold"
                        style="min-height: calc(-260px + 100vh);">
                        <img src="@/assets/imgs/noData-icon.png" alt="" class="img_no_data menuImg" />
                        <div class="no_data_text">{{ $t('coinDetailPage.暂无数据') }}</div>
                    </div>

                    <div v-if="orderList.length!==0" class="data_content ff_NunitoSemiBold"
                        style="min-height: calc(-260px + 100vh);">
                        <div v-for="(order,index) in orderList" :key="index">
                            <div class="orderList">
                                <div class="textHeader">{{ $t('订单号') }}:<span>{{ order.order_no }}</span></div>
                                <div class="textBody">
                                    <div>{{ $t('产品') }}：<span>{{ order.symbol }}</span></div>
                                    <div>{{ $t('购买数量') }}：<span>{{ Number(order.money) }}</span></div>
                                    <div>{{ $t('时间') }}：<span>{{ order.remain_time }}{{order.time_unit}}</span></div>
                                    <div>{{ $t('购买方向') }}：
                                        <span v-if="order.type===1">{{ $t('coinDetailPage.买涨') }}</span>
                                        <span v-if="order.type===2">{{ $t('coinDetailPage.买跌') }}</span>
                                    </div>
                                    <div>{{ $t('开仓时间') }}：<span>{{ ConvertTime(order.open_time) }}</span></div>
                                    <div>{{ $t('平仓时间') }}：<span>{{ ConvertTime(order.close_time) }}</span></div>

                                    <div>{{ $t('购买价') }}：<span>{{ Number(order.open_price) }}</span></div>
                                    <div v-if="tabType == 'hold'">{{ $t('现价') }}：<span>{{ coinData.price }}</span></div>
                                    <div v-else>{{ $t('平仓价') }}：<span>{{ Number(order.close_price) }}</span></div>

                                    <div>{{ $t('盈亏') }}：<span>{{ order.profits }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--BOTTOM-->
                <div class="opBoxBottom">
                    <div class="rateBox" @click="router.push({name:'contracts_orders',params:{coin:coinData.symbol}})">
                        <img class="rateBox_img" src="@/assets/imgs/TransactionRecord.png" alt="" />
                        <div class="rateTitle">{{ $t('coinDetailPage.记录') }}</div>
                    </div>
                    <div class="opItem">
                        <div class="opBtn fall" @click="toBuy('up')">
                            <div class="opItemTitle"><span>{{ $t('coinDetailPage.买涨') }}</span></div>
                        </div>
                    </div>
                    <div class="opItem">
                        <div class="opBtn rise" @click="toBuy('down')">
                            <div class="opItemTitle"><span>{{ $t('coinDetailPage.买跌') }}</span></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!--  买涨买跌弹出层-->
    <van-popup v-model:show="showBottom" position="bottom" :close-on-click-overlay="false" class="popup-content">
        <div class="popup-head">
            <div class="popup-left">{{coinData.symbol2}}/USDT{{ $t('coinDetailPage.交割') }}</div>
            <div class="popup-title"></div>
            <div class="popup-close" @click="showBottom=false">
                <div class="icon uicon icon-guanbi1"><span></span></div>
            </div>
        </div>
        <div class="popup-body">
            <div class="dealInfo">
                <div class="dealBaseInfo">
                    <img :src="coinData.logo" alt="" />
                    <div class="coinInfo-deal">
                        <div class="coinName">{{coinData.symbol2}}/USDT</div>
                        <div class="coinSymbol">
                            <div :style="direction === 'buy' ? { color: '#26a848' } : { color:'#ff3d3d' }" class="">
                                <span>{{ $t('coinDetailPage.买涨') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dealTimeInfo">
                    <div class="buyTime">
                        <img src="@/assets/imgs/icon_time.svg" alt="" class="buyTime-img" />
                        <div class="seconds"><span>{{ timeValue.time_num }}s</span></div>
                    </div>
                    <div class="buyAmount">{{ amountValue }} </div>
                </div>
            </div>
            <div class="timeBox amountBox">
                <div class="amountBoxTitle">{{ $t('coinDetailPage.交割时间') }}</div>
                <div class="amountInput">
                    <div class="walletTypeBox" @click="showTimePicker = true">
                        <img src="@/assets/imgs/icon_time.svg" alt="" />
                        <div class="walletSymbol"><span>{{ timeValue.time_num }}s~{{ timeValue.profit_ratio }}%</span>
                        </div>
                        <div class="icon uicon icon-xiala"><span></span></div>
                    </div>
                    <div class="amountInputOther">
                        <div class="trade-tab">
                            <div style="background: #26a848;color: white;" class="tradeItem tradeBuy"
                                @click="direction = 'buy'">{{
                                $t('coinDetailPage.买涨') }}</div>
                            <div style="background: #ff3d3d;color: white;" class="tradeItem tradeSell"
                                @click="direction = 'sell'">{{
                                $t('coinDetailPage.买跌') }}</div>
                            <div class="parallelogram"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="amountBox">
                <div class="amountBoxTitle">{{ $t('coinDetailPage.投资金额') }}</div>
                <div class="amountInput">
                    <div class="amountInputOther" style="width: 100% !important;padding-left: 0;">
                        <input v-model="inputValue" type="text" :placeholder="$t('coinDetailPage.金额')"
                            class="amountInputOther_input" />
                    </div>
                </div>
            </div>
            <div class="balanceBox">
                <div class="balanceBoxItem balanceInfo">{{ $t('coinDetailPage.余额') }}: {{ usdtAmount }} </div>
                <div class="balanceBoxItem feeInfo">{{ $t('coinDetailPage.预期收益') }} : {{ anticipateEarnings }} </div>
            </div>
            <div class="confirm-btn" @click="confirmPlaceOrder">
                <div :style="direction === 'buy' ? { background: '#26a848' } : { background:'#ff3d3d' }"
                    class="orderBtn"><span>{{ $t('coinDetailPage.确认下单') }}</span></div>
            </div>
        </div>
    </van-popup>

    <!--  时间选择器-->
    <van-popup v-model:show="showTimePicker" position="bottom">
        <van-picker :columns="timeColumns" @cancel="showTimePicker = false" @confirm="onConfirmTime" />
    </van-popup>

    <!-- <CoinPop></CoinPop> -->
    <ServiceComponents />
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import "@/assets/css/coin_detail.css";
import { useRouter,useRoute } from "vue-router";
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import { getCandlestickChart} from "@/assets/js/api/chart";
import { showConfirmDialog,showNotify  } from 'vant';
import { useI18n } from "vue-i18n";
import {
  getAccountUsdt, getContractsOrderList,
  getProductDetail,
  getProductKline, getTradingTimes, micOrderOpen,
} from "@/assets/js/api/interface";
import {connectionWebSocket, getTime} from "@/common/way";
import {CancelToken} from "axios";
import CoinPop from '@/components/CoinPop.vue';

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

//k线图时间选择
const showTabMore = ref(false);
//金额
const amountValue = ref('0');
//预期收益（预期收益=购买金额*收益率(秒数据的profit_ratio字段)）
const anticipateEarnings = computed(() => {
    if(timeValue.value){
        return Number(timeValue.value.profit_ratio) * Number(inputValue.value || 0) * 0.01
    }else{
        return 0.00
    }
});
//每个时间段对应的收益率
let timeThanYield = {};
//每个时间段对应的ID
let timeThanId = {};
//时间选择器
const timeArr = ref([])
const timeValue = ref();
const showTimePicker = ref(false);
const timeColumns = ref([]);

onMounted(() => {
    getCoinDetail();
    getCoinKline();
    //获取usdt钱包余额
    getUsdtWalletAmount();
    //获取时间配置
    getTimeConfig();
    //获取委托订单
    contractsOrderList();
    setupWebSocket();
})

//获取时间配置
const getTimeConfig = async () => {
  const result = await getTradingTimes(coinData.value.symbol);
    const timeArray = result.data.seconds;
    timeArr.value = timeArray;
    for (let i = 0; i < timeArray.length; i++) {
        const time = timeArray[i].time_num+timeArray[i].time_unit;
        timeThanYield[time] = parseFloat(timeArray[i].profit_ratio)/100;
        timeThanId[time] = timeArray[i].para_id;
        timeColumns.value.push({ text: time, value: i })
    }
    timeValue.value = timeArray[0];
}

//货币数据
const coinData = ref([]);
const coinDataKline = ref([]);//货币K线数据
const socket = ref();

//k线
const time = ref('1M');
function selectTime(num){
  if (num === 'more'){
      if (showTabMore.value){
          showTabMore.value = false
      }else{
          showTabMore.value = true;
      }  
      return
  }
  time.value = num;
    showTabMore.value = false;
}

//产品详情
let cancelTokenSource = null;//取消令牌
const getCoinDetail = async () => {
    const arr = localStorage.getItem('symbolArr')
    coinData.value = JSON.parse(arr)
}

//获取产品K线
const GetKData = ref();
const KlCahrt = ref();
const loading = ref(true);
const Interval = ref();
const getCoinKline = async () => {
    const kData = await getProductKline(coinData.value.symbol, time.value);
    GetKData.value = kData.data;
    KlCahrt.value = getCandlestickChart(kData.data);
    loading.value = false;
    Interval.value = setInterval(async() => {
        const kData = await getProductKline(coinData.value.symbol, time.value);
        GetKData.value = kData.data;
        KlCahrt.value = getCandlestickChart(kData.data);
    }, 30000);
}

//绘画k线图
watch(time,async()=>{
    loading.value = true;
    clearInterval(Interval.value)
    Interval.value = '';
    await getCoinKline();
    loading.value = false;
})

//委托订单、最新交易
const tabType = ref('hold');
function selectTab(id) {
    tabType.value = id;
}

//买涨买跌
const showBottom = ref(false);
let direction = ref();
function toBuy(type){
  showBottom.value = true;
    direction.value = type;
  setTimeout(()=>{
    amountValue.value = '0';
    inputValue.value = '';
  },100)
}

//时间选择
function onConfirmTime(data){
    showTimePicker.value = false;
    timeValue.value = timeArr.value[data.selectedOptions[0].value];
}

const inputValue = ref('');
watch(inputValue,()=>{
  if (inputValue.value===''){
    amountValue.value = '0';
    return;
  }
  amountValue.value = inputValue.value;
})

//确认下单
function confirmPlaceOrder(){
  if (parseFloat(amountValue.value)===0){
    showNotify({type:'warning',message:t('coinDetailPage.请输入投资金额')});
    return;
  }
  showConfirmDialog({
    message: t('coinDetailPage.确认要下单吗'),
    confirmButtonText: t('确认'),  // 修改确认按钮文字
    cancelButtonText: t('取消'),    // 修改取消按钮文字
  })
      .then(() => {
        // on confirm
        //0是余额,对接之后再改成动态
        if (usdtAmount.value<parseInt(amountValue.value)){
          showNotify({type:'danger',message:t('coinDetailPage.余额不足')})
          return;
        }
        //下单
        placeOrder();
      })
      .catch(() => {
        // on cancel
      });
}

//秒合约下单
const placeOrder = async () => {
  let data = {
    ticker:coinData.value.symbol,
    amount:amountValue.value,
    para_id: timeValue.value.para_id,
    direction:direction.value
  };
  try {
    const result = await micOrderOpen(data);
    //重新获取usdt余额
    getUsdtWalletAmount();
    //获取委托订单
    contractsOrderList();
    showBottom.value = false;
    amountValue.value = '';
    backMsgSuccess(result.msg)
  }catch (error) {
    console.error(error)
    amountValue.value = '0';
    inputValue.value = '';
  }
}

const count = ref();
// 倒计时
const CountDown = () => {
    if (!count.value){
        count.value = setInterval(() => {
            if (orderList.value.length > 0){
                orderList.value.forEach((item,index) => {
                    if (Number(item.remain_time) - 1 < 0){
                        tabType.value = 'history';
                        clearInterval(count.value);
                        count.value = '';
                        contractsOrderList();
                        return
                    }
                    orderList.value[index].remain_time = Number(item.remain_time) - 1;
                })
            }else{
                clearInterval(count.value);
                count.value = '';
            }
        },1000)
    }
}

//获取账户余额
const usdtAmount = ref(0);
const getUsdtWalletAmount = async () => {
  try {
    const data = await getAccountUsdt();
    usdtAmount.value = data.data.balance;
  }catch (error){
    console.error(error)
  }
}

watch(tabType,()=>{
    contractsOrderList();
})

const orderList = ref([]);
//获取委托订单
const contractsOrderList = async () => {
  orderList.value = [];
  const data = {
    page: 1,
    per_page: 10,
    type: tabType.value,
    ticker: coinData.value.symbol
  }
  try {
    const result = await getContractsOrderList(data);

    if(result.data.length > 0){
        orderList.value.push(...result.data)
        if (tabType.value == 'hold') {
            CountDown();
        } else {
            clearInterval(count.value);
            count.value = '';
        }
    }
  }catch (error) {
    console.error(error)
  }
}

const setupWebSocket = () => {
    socket.value = new WebSocket('wss://api.kucoinbyftrz.com:2391');

    socket.value.onmessage = (event) => {
        const data = JSON.parse(event.data);
        uplineData(data);
    };

    socket.value.onopen = () => {
        socket.value.send(JSON.stringify({ticker:coinData.value.symbol
        }))
    };
}


const uplineData = (newData) => {
    if(coinData.value){
        coinData.value.price = Number(newData.price);
        coinData.value.increase = newData.increase;
        try{
            if (GetKData.value.length != 0) {
                const data = {
                    timestamp: GetKData.value[GetKData.value.length - 1].timestamp,
                    open: newData.open,
                    close: newData.close,
                    high: newData.high,
                    low: newData.low,
                    volume: newData.vol
                }
                KlCahrt.value.updateData(data)
            }
        }catch(e){
            console.log(e)
        }

    }
}

const closetime = () => {
    clearInterval(count.value)
    clearInterval(Interval.value)
    count.value = '';
    Interval.value = '';
}

onBeforeUnmount(()=>{
    closetime();
    if (socket.value) {
        socket.value.close();
        console.log('WebSocket 连接已关闭')
    }
})

function backMsgSuccess(msg){
  showNotify({
    message: msg,
    color: '#fff',
    background: 'green',
  });
}

// 时间戳转换
const ConvertTime = (item) => {
    const date = new Date(Number(item) * 1000);
    return date.getFullYear() + '-' + Number(date.getMonth() + 1) + '-' + date.getDay() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
}

</script>

<style scoped>

</style>