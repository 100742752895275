<template>
  <div class="home-container">
    <div class="an-home">

      <div class="top-wrapper">
        <div class="top_container">
          <div class="header">
            <img src="@/assets/imgs/languages.png" alt="" class="languages" @click="router.push('/languages')"/>
            <div class="news-icon">
              <div @click="router.push('/notice')">
                <img src="@/assets/imgs/icon_bell.svg" alt=""/>
              </div>
            </div>
            <img src="@/assets/imgs/my.png" alt="" class="inco-box" @click="showLeft = true"/>
          </div>
          <div class="logo-box">
            <div class="logo"><img src="@/assets/imgs/rr53071.png" alt=""></div>
            <div class="title-box">
              <van-notice-bar scrollable style="width: 100%;background: transparent;padding: 0;color: rgb(255, 255, 255)">
                <div class="title-box_title">KuCoin Pro</div>
              </van-notice-bar>
              <div class="title-info over-line-5">{{ $t('homePage.开始赚钱计划') }}</div>
            </div>
          </div>
        </div>

        <div class="banner-box">
          <div class="box-header">
            <div class="header-icon"></div>
            <div class="header-text">{{ $t('homePage.快速进入') }}</div>
          </div>
          <van-swipe lazy-render class="list_wrapper">
            <van-swipe-item v-for="(text,index) in textData" :key="index">
              <div class="list_wrapper_item" @click="quickEntry(text.type)">
                <h2 class="item_title"> {{ text.textTitle }} </h2>
                <div class="item_text"> {{ text.textContent }} </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <div class="market_container">
        <div>
          <header class="market-header">
            <div class="header-icon"></div>
            <div class="header-text">{{ $t('homePage.市场') }}</div>
          </header>
          <div class="market-tabs">
            <div class="market-tab">
              <img src="@/assets/imgs/coin.png" v-if="PageType == 'DESC'"/>
              <img src="@/assets/imgs/coin_noSelect.png" v-else @click="SelectTab('DESC')" />
              <div v-if="PageType == 'DESC'" class="inner-box-name">{{ $t('homePage.数字货币') }}</div>
            </div>
            <div class="market-tab">
                <img src="@/assets/imgs/amountIncrease.png" v-if="PageType == 'ASC'"/>
                <img src="@/assets/imgs/amountIncrease_noSelect.png" v-else @click="SelectTab('ASC')" />
                <div v-if="PageType == 'ASC'" class="inner-box-name">{{ $t('homePage.涨幅') }}</div>
            </div>
          </div>

          <van-list v-model:loading="loading" :finished="finished" finished-text="Load complete"  @load="onLoad">
            <div>
              <div class="market_pro_list" v-for="(coin, index) in coinDataPage" :key="index" >
                <div class="market_pro_item" @click="GoToPage(coin)">
                  <div class="market_pro_base">
                    <img :src="coin.logo" alt="" />
                    <div>
                      <div class="market_pro_title">{{ coin.symbol2 }}</div>
                      <div class="market_pro_subtitle">{{ coin.unit }}</div>
                    </div>
                  </div>
                  <div class="market_pro_line">
                    <div class="lineBoard" style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: relative;" :id="coin.symbol"></div>
                  </div>
                  <div class="market_pro_detail">
                    <div class="market_pro_price">US<span>$</span> {{ Number(coin.price) }} </div>
                    <div class="market_pro_change">
                      <span class="change_value fc-8CC351 m-r-10" v-if="parseFloat(coin.increase)<0" style="color: #ff2d6d">{{ coin.increase }}%</span>
                      <span class="change_value fc-8CC351 m-r-10" v-else style="color: #36ffdb">{{ coin.increase }}%</span>
                      <span class="period">24 Hrs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>

        </div>
      </div>

      <div class="invite_container">
        <div class="box-header">
          <div class="header-icon"></div>
          <div class="header-text">{{$t('homePage.邀请朋友')}}</div>
        </div>
        <div class="invite_content">
          <h2 class="invite_title"> {{$t('homePage.邀请朋友加入')}} </h2>
          <div class="invite_text"> {{$t('homePage.开始邀请')}} </div>
          <div class="invite_btn flex-center" @click="router.push('/invite_friends')"> {{$t('homePage.查看')}} </div>
        </div>
      </div>

    </div>
  </div>

  <LeftMenu v-model:showLeft="showLeft" />
  <ServiceComponents/>
</template>

<script setup>
import '@/assets/css/home.css';
import ServiceComponents from '@/components/ServiceComponents.vue';
import LeftMenu from '@/components/LeftMenu.vue';
import { useRouter,useRoute } from 'vue-router';
import {compile, computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {getAreaData} from "@/assets/js/api/chart";
import { useI18n } from 'vue-i18n';
import {getProductDetail, getProductList} from "@/assets/js/api/interface";
import {connectionWebSocket} from "@/common/way";
import {CancelToken} from "axios";

const { t } = useI18n();
const router = useRouter();
const loading = ref(false);
const finished = ref(false);
const PageType = ref('DESC')
let PageIndex = 0;
let cancelTokenSource = CancelToken.source();//取消令牌
const showLeft = ref(false)
const PageData = ref([]);
const coinDataPage = computed(() => {
    if (PageData.value.length > 0) {
        if(PageType.value == 'DESC'){
            return [...PageData.value].sort((a, b) => {
                return b.increase - a.increase
            });
        }else{
            return [...PageData.value].sort((a, b) => {
                return a.increase - b.increase
            });
        }
        
    } else {
        return []
    }
})

onMounted(() => {
    setupWebSocket();
})

// 轮播图
const textData = [
    {
        "textTitle": t('homePage.Ai智能套利'),
        "textContent": t('homePage.200家交易所的智能交易'),
        "type": "arbitrage"
    },
    {
        "textTitle": t('homePage.矿机租赁'),
        "textContent": t('homePage.效率高,回报快'),
        "type": "mining"
    }
]

//AI套利跳转
function quickEntry(type) {
    if (type === 'arbitrage') {
        router.push('/arbitrage')
    }
    if (type === 'mining') {
        router.push('/mining')
    }
}

// 排序
const SelectTab = async(type) => {
    PageType.value = type;
    PageData.value = [];
    loading.value = false;
    finished.value = false;
    PageIndex = 0;
    onLoad();
}

const AreaDataClass = ref({})
// 获取产品列表
const GetData = async() => {
    PageIndex++;
    const arr = await getProductList(10, PageIndex, 'coin', PageType.value, cancelTokenSource.token)

    if(arr.data){
        loading.value = true
        PageData.value.push(...arr.data.data)
        setTimeout(()=>{
            arr.data.data.forEach((item) => {
                if (AreaDataClass.value[item.symbol]){
                    getAreaData(AreaDataClass.value[item.symbol].class,item, cancelTokenSource.token);
                }else{
                    const arrClass = getAreaData('', item, cancelTokenSource.token)
                    AreaDataClass.value[item.symbol] = { class: arrClass }
                }
                
            })
            
        },200)
        loading.value = false
        if (arr.data.data.length < 10){
            finished.value = true;
        }
    }else{
        loading.value = false;
        finished.value = true;
    }
}

// 跳转
const GoToPage = (item) => {
    const arr = JSON.stringify(item)
    localStorage.setItem('symbolArr', arr)
    router.push({ name: 'coin_detail', params: { coin: item.symbol } })
}

const onLoad = async() => {
    await GetData();
}

const socket = ref();
// WebSocket
const setupWebSocket = () => {
    socket.value = new WebSocket('wss://api.kucoinbyftrz.com:2390')

    socket.value.onmessage = (event) => {
        const data = JSON.parse(event.data);
        uplineData(data);
    };

    socket.value.onopen = () => {
        socket.value.send(JSON.stringify(
            {
                "sort": "ASC",
                "sort_column": "increase"
            }))
    };
}

const uplineData = (newData) => {
    let Data = {};
    newData.forEach((item) => {
        Data[item.symbol] = item;
    })
    if(PageData.value){
        PageData.value.forEach((item,index) => {
            PageData.value[index].price = Data[item.symbol].price;
            PageData.value[index].increase = Data[item.symbol].increase;
        })
    }
}

onBeforeUnmount(() => {
    socket.value.close();
    console.log('WebSocket 连接已关闭')
    cancelTokenSource.cancel('Request canceled on component unmount.');
})
</script>