export default {
    homePage:{
        '开始赚钱计划':'Verdienstplan starten',
        '快速进入':'Schnell starten',
        '市场':'Markt',
        '数字货币':'Digitale Währung',
        '涨幅':'Anstieg',
        '邀请朋友':'Freunde einladen',
        '邀请朋友加入':'Freunde zur Teilnahme einladen',
        '开始邀请':'Einladungen starten',
        '查看':'Anzeigen',
        'Ai智能套利':'AI-gesteuertes Arbitrage',
        '200家交易所的智能交易':'Intelligente Handelslösungen von 200 Börsen',
        '矿机租赁':'Mining-Mietservice',
        '效率高,回报快':'Hohe Effizienz, schnelle Rendite'
    },

    languagesPage:{
        '语言':'Sprache',
        '确认':'Bestätigen'
    },

    noticeRecord:{
        '通知':'Benachrichtigungen',
        '消息':'Nachrichten',
        '无数据':'Keine Daten',
    },

    leftPage:{
        '钱包':'Brieftasche',
        '套利':'Arbitrage',
        '采矿':'Mining',
        '设置':'Einstellungen',
    },

    accountPage:{
        '账户':'Konto',
        '立即发送加密货币':'Kryptowährung sofort senden',
        '选择一个钱包来发送加密货币':'Wählen Sie eine Brieftasche zum Senden von Kryptowährung',
        '选择一个钱包':'Eine Brieftasche auswählen',
        '搜索':'Suchen',
        '钱包':'Brieftasche',
    },

    walletPage:{
        "钱包": "Brieftasche",
        "可用": "Verfügbar",
        "冷冻": "Eingefroren",
        "接收": "Empfangen",
        "发送": "Senden",
        "转换": "Konvertieren",
        "存入资金": "Gelder Einzahlen",
        "退出": "Abmelden",
        "最大": "Max",
        "立即发送": "Jetzt Senden",
        "从": "Von",
        "到": "Bis",
        "费用": "Gebühr",
        "继续固定": "Festhalten Fortsetzen",
        "内容1": "Bitte senden Sie keine anderen Arten von Vermögenswerten an die oben angegebene Adresse. Dies kann zum Verlust Ihrer Vermögenswerte führen. Nach dem erfolgreichen Senden müssen Netzwerk-Knoten den Empfang der entsprechenden Vermögenswerte bestätigen. Bitte kontaktieren Sie daher rechtzeitig den Online-Kundendienst, um den Erhalt nach Abschluss der Überweisung zu überprüfen.",
        "内容2": "Bitte überweisen Sie keine Gelder an Fremde",
        "内容3": "Sie können nicht direkt zwischen zwei Kryptowährungen handeln. Sie sollten zuerst eine Kryptowährung in USDT umwandeln und dann USDT verwenden, um in eine andere Kryptowährung zu tauschen.",
        "资金密码": "Kapitalpasswort",
        "请输入资金密码": "Bitte Kapitalpasswort eingeben",
        "未设置资金密码": "Kapitalpasswort nicht gesetzt",
        "发送地址不能为空": "Sendeadresse darf nicht leer sein",
        "金额必须大于0": "Betrag muss größer als 0 sein"
    },

    tradingPage:{
        '交易记录':'Handelsaufzeichnungen',
        '接收':'Empfangen',
        '发送':'Senden',
        '转换':'Umwandeln',
        '无数据':'Keine Daten',
    },

    arbitragePage:{
        '主持工作':'Arbitrage-Aufgaben',
        '总套利':'Gesamtarbitrage',
        '今日收益':'Heutiger Gewinn',
        '托管订单':'Treuhandaufträge',
        '介绍':'Einführung',
        '人工智能机器人是如何工作的':'Wie funktioniert der KI-Roboter',
        '套利产品':'Arbitrage-Produkte',
        'AI智能挖矿':'AI-gesteuertes Mining',
        '低买高卖获利':'Günstig kaufen, teuer verkaufen und profitieren',
    },

    trusteeshipDetailPage:{
        '托管详细':'Treuhand-Details',
        '主持':'Arbitrage',
        '终止':'Beenden',
        '赎回':'Einlösen',
        '无数据':'Keine Daten',
    },

    miningPage:{
        '你值得信任的量化投資':'Vertrauenswürdige quantitative Investitionen',
        '立即开户':'Jetzt eröffnen',
        '总流动性矿池金额':'Gesamtbetrag des Liquiditäts-Pools',
        '质押人數':'Staking-Anzahl',
        '质押':'Staking',
        '质押记录':'Staking-Aufzeichnungen',
        '质押收益':'Staking-Erträge',
        '输入要质押的USDT数量':'Geben Sie die Anzahl der USDT ein, die gestakt werden sollen',
        '质押总量':'Gesamtmenge des Stakings',
        '最大限度':'Höchstgrenze',
        '余额':'Guthaben',
        '钱包余额':'Brieftaschenguthaben',
        '收益率':'Rendite',
        '选择质押的期限':'Staking-Dauer auswählen',
        '没有记录':'Keine Aufzeichnungen',
        '挖矿收益地址':'Mining-Ertragsadresse',
        '数量':'Menge',
        '相关问题':'Häufige Fragen',
        '什麼是AI量化交易':'Was ist AI-quantitativer Handel',
        '文本1':'Quantitativer Handel ist ein Ansatz, der auf Unterricht und statistischer Analyse basiert, um den Wert von Finanzanlagen (z.B. Aktien oder Optionen, Kryptowährungen) zu bestimmen. Quantitative Händler verwenden verschiedene Daten, einschließlich historischer Investitionen und Marktdaten, um Handelsalgorithmen und Computermodelle zu entwickeln. Diese Computermodelle liefern Informationen, die Investoren helfen, Investitionsmöglichkeiten zu analysieren und erfolgreiche Handelsstrategien zu entwickeln. In der Regel umfasst diese Handelsstrategie sehr spezifische Informationen über Einstiegspunkte, Ausstiegspunkte, die erwarteten Risiken und erwarteten Renditen. Das endgültige Ziel der finanziellen quantitativen Analyse ist es, quantifizierbare Statistiken und Indikatoren zu verwenden, um Investoren bei der Entscheidungsfindung für profitable Investitionen zu unterstützen.',
        '为什么要支付手续费':'Warum Gebühren zahlen',
        '文本2':'Da Sie ein Konto für das quantitative Trading eröffnen müssen, wird die ETH als Kontogebühr erhoben.',
        'AI量化交易的优势':'Vorteile des AI-quantitativen Handels',
        '文本3_1':'Keine manuelle Eingriffe erforderlich',
        '文本3_2':'AI-quantitativer Handel reduziert kognitive Fehler',
        '文本3_3':'AI-quantitative Handelsstrategien maximieren die Effizienz',
        '文本3_4':'AI-Quantitätsstrategien erlauben Backtesting',
        '文本3_5':'Unbegrenzte Anzahl von Strategien umsetzbar',
        '文本3_6':'Automatisierter Handel befreit die Zeit der Investoren',
        '文本3_7':'Quantitative Handelssysteme zeigen Konsistenz zwischen Ausführung und Backtesting',
        '文本3_8':'Das System hilft Ihnen, Ihrem Plan zu folgen',
        '在哪里进行AI量化交易':'Wo wird AI-quantitativer Handel durchgeführt',
        '文本4':'Quantitativer Handel in Zusammenarbeit mit Coinbase, alle Programme laufen in der Coinbase-Brieftasche, es handelt sich um ein transparentes und faires AI-quantitatives Handelsprojekt.',
        '为什么会有这项目':'Warum gibt es dieses Projekt',
        '文本5':'Im Jahr 2022 haben wir offiziell mit Coinbase zusammengearbeitet, um einen neuen AI+Finanz-Ökosystem-Konsenskreis aufzubauen. Zusammen mit Coinbase haben wir 2 Milliarden USD investiert und eine quantitative Handelsplattform entwickelt, um eine perfekte Integration von AI und Finanzen zu erreichen. Das Kernprodukt ist der AI-Analyse-Chip, der in unsere neueste Version des 6.2.1 AI-Roboters integriert ist. Seine Einführung hat viele Branchenkonventionen durchbrochen, einschließlich der traditionellen Finanztransaktionen. Die Nutzung der quantitativen Handelsplattform macht den Handel einfacher. Als nächstes wird die neue Generation der Krypto-Asset-Handelsplattform weiterentwickelt und die Web3.0-Version stark eingeführt.',
        '当前总质押':'Aktuell gestaktes Gesamtvolumen',
        '当日收益':'Tagesrendite',
        '总收益':'Gesamtrendite',
    },

    inviteFriendsPage:{
        '邀请朋友':'Freunde einladen',
        '邀请朋友获得推荐奖励':'Freunde einladen und Empfehlungsprämien erhalten',
        '好友参与即可获得推荐奖励':'Freunde, die an AI-Arbitrage und Mining-Mietdiensten teilnehmen, erhalten Empfehlungsprämien',
        '您的邀请成功':'Ihre Einladung war erfolgreich',
        '全部':'Alle',
        '直接':'Direkt',
        '间接':'Indirekt',
        '更多':'Mehr',
        '直接收益':'Direkte Erträge',
        '间接收益':'Indirekte Erträge',
        '无数据':'Keine Daten',
        '复制成功':'Kopieren erfolgreich',
    },

    coinDetailPage:{
        '交割合约':'Terminkontrakt',
        '最低价':'Niedrigster Preis',
        '成交量':'Handelsvolumen',
        '最高价':'Höchster Preis',
        '成交额':'Handelswert',
        '时间':'Zeit',
        '更多':'Mehr',
        '委托订单':'Aufträge',
        '最新交易':'Neueste Transaktionen',
        '暂无数据':'Keine Daten verfügbar',
        '记录':'Aufzeichnungen',
        '买涨':'Long',
        '买跌':'Short',
        '交割':'Fälligkeit',
        '交割时间':'Fälligkeitszeit',
        '投资金额':'Investitionsbetrag',
        '金额':'Betrag',
        '余额':'Guthaben',
        '预期收益':'Erwartete Rendite',
        '确认下单':'Bestellung bestätigen',
        '请输入投资金额':'Bitte Investitionsbetrag eingeben',
        '确认要下单吗':'Möchten Sie die Bestellung bestätigen?',
        '余额不足':'Unzureichendes Guthaben',
    },

    contractsOrderPage:{
        '委托订单':'Aufträge',
        '等待中':'Wartend',
        '已結束':'Abgeschlossen',
        '无数据':'Keine Daten',
    },

    numKeyboard:{
        '完成':'Fertig',
        '输入金额不能超过可用金额':'Der eingegebene Betrag darf das verfügbare Guthaben nicht überschreiten',
    },

    settingPage:{
        "设置": "Einstellungen",
        "修改资金密码": "Kapitalpasswort ändern",
        "旧密码": "Altes Passwort",
        "请输入旧密码": "Bitte altes Passwort eingeben",
        "新密码": "Neues Passwort",
        "请输入新密码": "Bitte neues Passwort eingeben",
        "确认密码": "Passwort bestätigen",
        "请再次输入新密码": "Bitte neues Passwort erneut eingeben",
        "密码不能为空": "Passwort darf nicht leer sein",
        "新密码和确认密码不匹配": "Neues Passwort und Bestätigungspasswort stimmen nicht überein",
        "取消": "Abbrechen",
        "确认": "Bestätigen",
        "设置资金密码": "Kapitalpasswort festlegen",
        "资金密码": "Kapitalpasswort"
    },

    interfaceJs: {
        "获取失败": "Abruf fehlgeschlagen",
        "登录失败": "Anmeldung fehlgeschlagen",
        "设置成功": "Einstellungen erfolgreich gespeichert",
        "修改成功": "Änderung erfolgreich",
        "添加失败": "Hinzufügung fehlgeschlagen",
        "修改失败": "Änderung fehlgeschlagen"
    },

    "时间": "Zeit",
    "数量": "Menge",
    "状态": "Status",
    "审核中": "In Prüfung",
    "已完成": "Abgeschlossen",
    "审核未通过": "Prüfung Fehlgeschlagen",
    "购买金额": "Kaufbetrag",
    "全部": "Alle",
    "输入金额": "Betrag Eingeben",
    "可用余额": "Verfügbares Guthaben",
    "大于最大可投": "Größer als Maximalinvestition",
    "购买": "Kaufen",
    "购买成功": "Kauf Erfolgreich",
    "请输入购买金额": "Bitte Kaufbetrag Eingeben",
    "订单号": "Bestellnummer",
    "购买日期": "Kaufdatum",
    "结束日期": "Enddatum",
    "预计收益": "Erwarteter Gewinn",
    "日收益率": "Tagesrendite",
    "取消托管": "Verwahrung Aufheben",
    "接收金额": "Erhaltenes Geld",
    "请填写金额": "Bitte Betrag Eingeben",
    "上传充值凭证": "Nachweis Hochladen",
    "充值": "Aufladen",
    "请上传图片": "Bitte Bild Hochladen",
    "请输入金额": "Bitte Betrag Eingeben",
    "确认": "Bestätigen",
    "产品": "Produkt",
    "购买数量": "Kaufmenge",
    "购买方向": "Kaufrichtung",
    "开仓时间": "Eröffnungszeit",
    "平仓时间": "Schließungszeit",
    "盈亏": "Gewinn und Verlust",
    "取消": "Abbrechen",
    "质押数量": "Beleihungsmenge",
    "期限": "Laufzeit",
    "开始时间": "Beginnzeit",
    "结束时间": "Endzeit",
    "收益率": "Rendite",
    "天": "Tage",
    "质押金额必须大于0": "Beleihungsbetrag Muss Größer Als 0 Sein",
    "请选择质押产品": "Bitte Beleihungsprodukt Auswählen",
    "选择质押的产品": "Ausgewähltes Pfandprodukt",
    "限额": "Limit",
    "周期": "Zyklus",
    "预警收益": "Warngewinn",
    "数量限制": "Mengenbeschränkung",
    "最少可投": "Mindestinvestition",
    "最大可投": "Maximalinvestition",
    "立即租用": "Jetzt Mieten",
    "详情": "Details",
    "收益金额": "Ertragsbetrag",

    "身份认证": "Identitätsprüfung",
    "个人身份认证": "Persönliche Identitätsprüfung",
    "完成认证后可获得的权限": "Berechtigungen nach Abschluss",
    "权限一": "Erhöhung des Abhebungslimits, 500 BTC Limit pro 24 Stunden",
    "权限二": "Erhöhung des Fiat-Handelslimits, 1.000.000 USDT pro Transaktion",
    "去认证": "Zur Authentifizierung",
    "已认证": "Zertifiziert",
    "证件类型": "Dokumentenart",
    "身份证": "Personalausweis",
    "姓名": "Name",
    "证件号码": "Dokumentennummer",
    "上传证件正面": "Vorderseite des Dokuments hochladen",
    "正面证件照": "Vorderansicht des Dokuments",
    "上传证件反面": "Rückseite des Dokuments hochladen",
    "反面证件照": "Rückansicht des Dokuments",
    "确定": "Bestätigen",
    "请输入姓名": "Bitte geben Sie Ihren Namen ein",
    "请输入证件号码": "Bitte geben Sie Ihre Dokumentennummer ein",
    "请上传证件": "Bitte Dokument hochladen",
    "查看认证信息": "Zertifizierungsinformationen anzeigen",
}
