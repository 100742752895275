<template>
    <van-number-keyboard :show="showNumKeyboard" theme="custom" :extra-key="['00', '.']"
        :close-button-text="$t('numKeyboard.完成')" @blur="handleBlur" @input="handleInput" @delete="handleDelete"
        class="num_keyboard" />
</template>

<script setup>
import {defineProps, defineEmits, ref, watch, onMounted} from "vue";
import {showNotify} from "vant";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const CountNumber = ref();
const props = defineProps({
  showNumKeyboard: Boolean,
  inputType: String,
  availableAmount: String,
  currencyRate: Number
});

const emit = defineEmits(['update:showNumKeyboard', 'updata:CountNumber', 'delete:CountNumber']);

const showNumKeyboard = ref(props.showNumKeyboard);
const inputType = ref(props.inputType);
const availableAmount = ref(props.availableAmount);
const currencyRate = ref(props.currencyRate);

const handleBlur = () => {
  emit('update:showNumKeyboard', false);
};

const handleInput = (value) => {
    emit('updata:CountNumber', value)
};

const handleDelete = () => {
    emit('delete:CountNumber',true)
};

// Watch props to update reactive states
watch(() => props.showNumKeyboard, (newValue) => showNumKeyboard.value = newValue);
watch(() => props.inputType, (newValue) => inputType.value = newValue);
watch(() => props.availableAmount, (newValue) => availableAmount.value = newValue);
watch(() => props.currencyRate, (newValue) => currencyRate.value = newValue);
</script>

<style scoped>
.num_keyboard{
  padding-bottom: 0;
}
.num_keyboard ::v-deep .van-number-keyboard__body{
  background: #023469 !important;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key{
  background: #5789bd;
  color: #fff;
  font-family: Poppins,sans-serif;
  font-size: 5.33333vw;
  font-weight: 500;
  line-height: 6.4vw;
  letter-spacing: 0;
  text-align: center;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key--active{
  background-color: rgba(87,137,189,0.7) !important; /* 修改按钮点击效果颜色 */
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key--blue {
  background: #1a9ffd !important;
}
</style>