export default {
    homePage:{
        '开始赚钱计划':'開始賺錢計劃',
        '快速进入':'快速進入',
        '市场':'市場',
        '数字货币':'數字貨幣',
        '涨幅':'漲幅',
        '邀请朋友':'邀請朋友',
        '邀请朋友加入':'邀請朋友加入',
        '开始邀请':'開始邀請',
        '查看':'查看',
        'Ai智能套利':'Ai智能套利',
        '200家交易所的智能交易':'200 家交易所的智能交易',
        '矿机租赁':'礦機租賃',
        '效率高,回报快':'效率高,回報快'
    },

    languagesPage:{
        '语言':'語言',
        '确认':'確認'
    },

    noticeRecord:{
        '通知':'通知',
        '消息':'消息',
        '无数据':'無數據',
    },

    leftPage:{
        '钱包':'錢包',
        '套利':'套利',
        '采矿':'採礦',
        '设置':'設置',
    },

    accountPage:{
        '账户':'帳戶',
        '立即发送加密货币':'立即發送加密貨幣',
        '选择一个钱包来发送加密货币':'選擇一個錢包來發送加密貨幣',
        '选择一个钱包':'選擇一個錢包',
        '搜索':'搜尋',
        '钱包':'錢包',
    },

    walletPage:{
        "钱包": "錢包",
        "可用": "可用",
        "冷冻": "冷凍",
        "接收": "接收",
        "发送": "發送",
        "转换": "轉換",
        "存入资金": "存入資金",
        "退出": "退出",
        "最大": "最大",
        "立即发送": "立即發送",
        "从": "從",
        "到": "到",
        "费用": "費用",
        "继续固定": "繼續固定",
        "内容1": "請不要將其他類型的資產發送到上述地址。此操作可能會導致您的資產丟失。發送成功後，網絡節點需要確認接收相應的資產。因此，當您完成轉賬時，請及時聯繫在線客服核實到貨。",
        "内容2": "請不要將資金轉給陌生人",
        "内容3": "您不能直接在兩種加密貨幣之間進行交易。您應該先將一種加密貨幣兌換成USDT，然後再用USDT兌換成任何其他加密貨幣。",
        "资金密码": "資金密碼",
        "请输入资金密码": "請輸入資金密碼",
        "未设置资金密码": "未設置資金密碼",
        "发送地址不能为空": "發送地址不能為空",
        "金额必须大于0": "金額必須大於0"
    },

    tradingPage:{
        '交易记录':'交易記錄',
        '接收':'接收',
        '发送':'發送',
        '转换':'轉換',
        '无数据':'無數據',
    },

    arbitragePage:{
        '主持工作':'主持工作',
        '总套利':'總套利',
        '今日收益':'今日收益',
        '托管订单':'托管訂單',
        '介绍':'介紹',
        '人工智能机器人是如何工作的':'人工智能機器人是如何工作的',
        '套利产品':'套利產品',
        'AI智能挖矿':'AI智能挖礦',
        '低买高卖获利':'低買高賣獲利',
    },

    trusteeshipDetailPage:{
        '托管详细':'托管詳細',
        '主持':'主持',
        '终止':'終止',
        '赎回':'贖回',
        '无数据':'無數據',
    },

    miningPage:{
        '你值得信任的量化投資':'你值得信任的量化投資',
        '立即开户':'立即開戶',
        '总流动性矿池金额':'總流動性礦池金額',
        '质押人數':'質押人數',
        '质押':'質押',
        '质押记录':'質押記錄',
        '质押收益':'質押收益',
        '输入要质押的USDT数量':'輸入要質押的USDT數量',
        '质押总量':'質押總量',
        '最大限度':'最大限度',
        '余额':'餘額',
        '钱包余额':'錢包餘額',
        '收益率':'收益率',
        '选择质押的期限':'選擇質押的期限',
        '没有记录':'沒有記錄',
        '挖矿收益地址':'挖礦收益地址',
        '数量':'數量',
        '相关问题':'相關問題',
        '什麼是AI量化交易':'什麼是AI量化交易',
        '文本1':'金融量化交易是一種強調教學和統計分析以幫助確定金融資產（例如股票或期權，加密貨幣）價值的方法。量化交易者使用各種數據—包括歷史投資和股票市場數據—來開發交易算法和計算機模型。這些計算機模型生成的信息有助於投資者分析投資機會並制定成功的交易策略。通常，此交易策略將包括有關進場和出場點、交易的預期風險和預期回報的非常具體的信息。財務量化分析的最終目標是使用可量化的統計數據和指標來幫助投資者做出有利可圖的投資決策。',
        '为什么要支付手续费':'為什麼要支付手續費',
        '文本2':'由於你需要在量化交易中開設一個賬戶進行質押投資，所以收取的ETH是作為你的開戶手續費。',
        'AI量化交易的优势':'AI量化交易的優勢',
        '文本3_1':'無需人工干預',
        '文本3_2':'AI量化交易減少認知錯誤',
        '文本3_3':'AI量化交易策略讓成效加倍',
        '文本3_4':'AI量化策略允許回測',
        '文本3_5':'可以實施無限數量的策略',
        '文本3_6':'自動交易解放投資者的時間',
        '文本3_7':'量化交易系統執行與回測的相同性',
        '文本3_8':'系統讓您遵循您的計劃',
        '在哪里进行AI量化交易':'在哪裡進行AI量化交易',
        '文本4':'和Coinbase共同打造的量化交易，所有的程序都於Coinbase錢包中運行，是公開公正的AI量化交易項目。',
        '为什么会有这项目':'為什麼會有這項目',
        '文本5':'2022年，和Coinbase正式合作，致力於打造全新AI+金融生態共識圈，和Coinbase共同投資20億美元的資金，聯合推出打造量化交易平台，實現AI+金融完美融合，核心產品是AI分析計算芯片，衍生出的它被搭載於我們最新款6.2.1AI機器人上，他的問世打破並顛覆了多個行業的傳統，其中就包括金融交易的傳統，使用量化交易平台，讓交易變得更加簡單。下一步，作為新一代加密資產交易平台還將持續發力，強勢推出Web3.0版本。',
        '当前总质押':'當前總質押',
        '当日收益':'當日收益',
        '总收益':'總收益',
    },

    inviteFriendsPage:{
        '邀请朋友':'邀請朋友',
        '邀请朋友获得推荐奖励':'邀請朋友 獲得推薦獎勳',
        '好友参与即可获得推荐奖励':'好友參與AI套利,租礦機，即可獲得推薦獎勳',
        '您的邀请成功':'您的邀請成功',
        '全部':'全部',
        '直接':'直接',
        '间接':'間接',
        '更多':'更多',
        '直接收益':'直接收益',
        '间接收益':'間接收益',
        '无数据':'無數據',
        '复制成功':'複製成功',
    },

    coinDetailPage:{
        '交割合约':'交割合約',
        '最低价':'最低價',
        '成交量':'成交量',
        '最高价':'最高價',
        '成交额':'成交額',
        '时间':'時間',
        '更多':'更多',
        '委托订单':'委託訂單',
        '最新交易':'最新交易',
        '暂无数据':'暫無數據',
        '记录':'記錄',
        '买涨':'買漲',
        '买跌':'買跌',
        '交割':'交割',
        '交割时间':'交割時間',
        '投资金额':'投資金額',
        '金额':'金額',
        '余额':'餘額',
        '预期收益':'預期收益',
        '确认下单':'確認下單',
        '请输入投资金额':'請輸入投資金額',
        '确认要下单吗':'確認要下單嗎？',
        '余额不足':'餘額不足',
    },

    contractsOrderPage:{
        '委托订单':'委託訂單',
        '等待中':'等待中',
        '已結束':'已結束',
        '无数据':'無數據',
    },

    numKeyboard:{
        '完成':'完成',
        '输入金额不能超过可用金额':'輸入金額不能超過可用金額',
    },

    settingPage:{
        "设置": "設定",
        "修改资金密码": "修改資金密碼",
        "旧密码": "舊密碼",
        "请输入旧密码": "請輸入舊密碼",
        "新密码": "新密碼",
        "请输入新密码": "請輸入新密碼",
        "确认密码": "確認密碼",
        "请再次输入新密码": "請再次輸入新密碼",
        "密码不能为空": "密碼不能為空",
        "新密码和确认密码不匹配": "新密碼和確認密碼不匹配",
        "取消": "取消",
        "确认": "確認",
        "设置资金密码": "設置資金密碼",
        "资金密码": "資金密碼"
    },

    interfaceJs: {
        "获取失败": "取得失敗",
        "登录失败": "登入失敗",
        "设置成功": "設置成功",
        "修改成功": "修改成功",
        "添加失败": "添加失敗",
        "修改失败": "修改失敗"
    },

    "时间": "時間",
    "数量": "數量",
    "状态": "狀態",
    "审核中": "審核中",
    "已完成": "已完成",
    "审核未通过": "審核未通過",
    "购买金额": "購買金額",
    "全部": "全部",
    "输入金额": "輸入金額",
    "可用余额": "可用餘額",
    "大于最大可投": "大於最大可投",
    "购买": "購買",
    "购买成功": "購買成功",
    "请输入购买金额": "請輸入購買金額",
    "订单号": "訂單號",
    "购买日期": "購買日期",
    "结束日期": "結束日期",
    "预计收益": "預計收益",
    "日收益率": "日收益率",
    "取消托管": "取消托管",
    "接收金额": "接收金額",
    "请填写金额": "請填寫金額",
    "上传充值凭证": "上傳充值憑證",
    "充值": "充值",
    "请上传图片": "請上傳圖片",
    "请输入金额": "請輸入金額",
    "确认": "確認",
    "产品": "產品",
    "购买数量": "購買數量",
    "购买方向": "購買方向",
    "开仓时间": "開倉時間",
    "平仓时间": "平倉時間",
    "盈亏": "盈虧",
    "取消": "取消",
    "质押数量": "質押數量",
    "期限": "期限",
    "开始时间": "開始時間",
    "结束时间": "結束時間",
    "收益率": "收益率",
    "天": "天",
    "质押金额必须大于0": "質押金額必須大於0",
    "请选择质押产品": "請選擇質押產品",
    "选择质押的产品": "選擇質押的產品",
    "限额": "限額",
    "周期": "周期",
    "预警收益": "預警收益",
    "数量限制": "數量限制",
    "最少可投": "最少可投",
    "最大可投": "最大可投",
    "立即租用": "立即租用",
    "详情": "詳細",
    "收益金额": "收益金額",

    "身份认证": "身份認證",
    "个人身份认证": "個人身份認證",
    "完成认证后可获得的权限": "完成認證後可獲得的權限",
    "权限一": "增加提現額度，24小時限額500BTC",
    "权限二": "增加法幣交易額度，單筆限額1000000USDT",
    "去认证": "去認證",
    "已认证": "已認證",
    "证件类型": "證件類型",
    "身份证": "身分證",
    "姓名": "姓名",
    "证件号码": "證件號碼",
    "上传证件正面": "上傳證件正面",
    "正面证件照": "正面證件照",
    "上传证件反面": "上傳證件反面",
    "反面证件照": "反面證件照",
    "确定": "確定",
    "请输入姓名": "請輸入姓名",
    "请输入证件号码": "請輸入證件號碼",
    "请上传证件": "請上傳證件",
    "查看认证信息": "查看認證資訊"
}
