<template>
  <div style="height: 100%;overflow: auto">
    <div>
      <div class="setting">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{ $t('settingPage.设置') }} </span>
        </div>

        <div class="setContent">
          <div class="setCss" @click="showDialog">
            <span class="setText">{{ $t('settingPage.资金密码') }}</span>
            <div class="setIcon"><van-icon name="arrow" class="setIcon_img" /></div>
          </div>

          <div class="setCss" @click="router.push('identityAuthBefore')">
            <span class="setText">{{ $t('身份认证') }}</span>
            <div class="setIcon"><van-icon name="arrow" class="setIcon_img" /></div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <van-dialog v-model:show="showUpdateDialog" :title="$t('settingPage.修改资金密码')" show-cancel-button class="dialog"
              @confirm="handleConfirm" :cancel-button-text="$t('settingPage.取消')" :confirm-button-text="$t('settingPage.确认')" @cancel="handleCancel">
    <div class="inputDiv">
      <van-field v-model="oldPassword" type="password" :label="$t('settingPage.旧密码')" :placeholder="$t('settingPage.请输入旧密码')" />
      <van-field v-model="newPassword" type="password" :label="$t('settingPage.新密码')" :placeholder="$t('settingPage.请输入新密码')" />
      <van-field v-model="confirmPassword" type="password" :label="$t('settingPage.确认密码')" :placeholder="$t('settingPage.请再次输入新密码')" />
    </div>
  </van-dialog>

  <van-dialog v-model:show="showAddDialog" :title="$t('settingPage.设置资金密码')" show-cancel-button class="dialog"
              @confirm="handleConfirm" :cancel-button-text="$t('settingPage.取消')" :confirm-button-text="$t('settingPage.确认')" @cancel="handleCancel">
    <div class="inputDiv">
      <van-field v-model="newPassword" type="password" :label="$t('settingPage.新密码')" :placeholder="$t('settingPage.请输入新密码')" />
      <van-field v-model="confirmPassword" type="password" :label="$t('settingPage.确认密码')" :placeholder="$t('settingPage.请再次输入新密码')" />
    </div>
  </van-dialog>

  <ServiceComponents/>
</template>

<script setup>
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/setting.css';
import { useRouter,useRoute } from "vue-router";
import { ref } from "vue";
import { showNotify } from "vant";
import { useI18n } from "vue-i18n";
import {addOrUpWithdrawPwd, getUserInfo} from "@/assets/js/api/interface";

const router = useRouter();
const { t } = useI18n();

//获取用户信息
const route = useRoute();
let userInfo = route.meta.fetchedData;

const showUpdateDialog = ref(false);
const showAddDialog = ref(false);
const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');

function showDialog(){
  if (userInfo.has_safe_password===0){
    showAddDialog.value = true
  }else {
    showUpdateDialog.value = true;
  }
}

const handleConfirm = async () => {
  // 处理确认逻辑
  if (userInfo.has_safe_password===1){
    if (oldPassword.value === '' || newPassword.value === ''){
      backMsg('密码不能为空');
      clearColumnData();
      return;
    }
  }else {
    if (newPassword.value===''){
      backMsg('密码不能为空');
      clearColumnData();
      return;
    }
  }
  if (newPassword.value !== confirmPassword.value) {
    backMsg('新密码和确认密码不匹配');
    clearColumnData();
    return;
  }

  try {
    const setData = await addOrUpWithdrawPwd(oldPassword.value,newPassword.value,confirmPassword.value);
    backMsgSuccess(setData.msg)
    clearColumnData();

    const data = await getUserInfo();
    userInfo = data.data
  }catch (e) {
    console.error(e)
  }
};

function backMsg(msg){
  showNotify({
    message: t('settingPage.'+msg),
    color: '#fff',
    background: 'red',
  });
}

function backMsgSuccess(msg){
  showNotify({
    message: msg,
    color: '#fff',
    background: 'green',
  });
}

function clearColumnData(){
  oldPassword.value = '';
  newPassword.value = '';
  confirmPassword.value = '';
}

const handleCancel = () => {
  // 处理取消逻辑
  clearColumnData();
};
</script>