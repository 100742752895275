export default {
    homePage: {
        '开始赚钱计划': 'Start Earning Plan',
        '快速进入': 'Quick Access',
        '市场': 'Market',
        '数字货币': 'Cryptocurrency',
        '涨幅': 'Change',
        '邀请朋友': 'Invite Friends',
        '邀请朋友加入': 'Invite Friends to Join',
        '开始邀请': 'Start Inviting',
        '查看': 'View',
        'Ai智能套利': 'AI Smart Arbitrage',
        '200家交易所的智能交易': 'Smart Trading Across 200 Exchanges',
        '矿机租赁': 'Mining Machine Rental',
        '效率高,回报快': 'High Efficiency, Fast Returns'
    },

    languagesPage: {
        '语言': 'Language',
        '确认': 'Confirm'
    },

    noticeRecord: {
        '通知': 'Notification',
        '消息': 'Message',
        '无数据': 'No Data',
    },

    leftPage: {
        '钱包': 'Wallet',
        '套利': 'Arbitrage',
        '采矿': 'Mining',
        '设置': 'Settings',
    },

    accountPage: {
        '账户': 'Account',
        '立即发送加密货币': 'Send Cryptocurrency Now',
        '选择一个钱包来发送加密货币': 'Select a Wallet to Send Cryptocurrency',
        '选择一个钱包': 'Select a Wallet',
        '搜索': 'Search',
        '钱包': 'Wallet',
    },

    walletPage: {
        "钱包": "Wallet",
        "可用": "Available",
        "冷冻": "Frozen",
        "接收": "Receive",
        "发送": "Send",
        "转换": "Convert",
        "存入资金": "Deposit Funds",
        "退出": "Logout",
        "最大": "Max",
        "立即发送": "Send Now",
        "从": "From",
        "到": "To",
        "费用": "Fee",
        "继续固定": "Continue Fixed",
        "内容1": "Please do not send other types of assets to the above address. This operation may result in the loss of your assets. After successful sending, network nodes need to confirm the receipt of the corresponding assets. Therefore, please contact online customer service in time to verify the receipt when you complete the transfer.",
        "内容2": "Please do not transfer funds to strangers",
        "内容3": "You cannot directly trade between two cryptocurrencies. You should first convert one cryptocurrency to USDT, and then use USDT to exchange for any other cryptocurrency.",
        "资金密码": "Fund Password",
        "请输入资金密码": "Please enter the fund password",
        "未设置资金密码": "Fund password not set",
        "发送地址不能为空": "Send address cannot be empty",
        "金额必须大于0": "Amount must be greater than 0"
    },

    tradingPage: {
        '交易记录': 'Transaction Records',
        '接收': 'Receive',
        '发送': 'Send',
        '转换': 'Convert',
        '无数据': 'No Data',
    },

    arbitragePage: {
        '主持工作': 'Arbitrage',
        '总套利': 'Total Arbitrage',
        '今日收益': 'Today\'s Earnings',
        '托管订单': 'Managed Orders',
        '介绍': 'Introduction',
        '人工智能机器人是如何工作的': 'How AI Robots Work',
        '套利产品': 'Arbitrage Products',
        'AI智能挖矿': 'AI Smart Mining',
        '低买高卖获利': 'Buy Low, Sell High Profits',
    },

    trusteeshipDetailPage: {
        '托管详细': 'Trusteeship Details',
        '主持': 'Arbitrage',
        '终止': 'Terminate',
        '赎回': 'Redeem',
        '无数据': 'No Data',
    },

    miningPage: {
        '你值得信任的量化投資': 'Trusted Quantitative Investment',
        '立即开户': 'Open Account Now',
        '总流动性矿池金额': 'Total Liquidity Pool Amount',
        '质押人數': 'Number of Stakers',
        '质押': 'Stake',
        '质押记录': 'Staking Records',
        '质押收益': 'Staking Earnings',
        '输入要质押的USDT数量': 'Enter USDT Amount to Stake',
        '质押总量': 'Total Staked Amount',
        '最大限度': 'Maximum Limit',
        '余额': 'Balance',
        '钱包余额': 'Wallet Balance',
        '收益率': 'Yield',
        '选择质押的期限': 'Select Staking Duration',
        '没有记录': 'No Records',
        '挖矿收益地址': 'Mining Earnings Address',
        '数量': 'Amount',
        '相关问题': 'FAQs',
        '什麼是AI量化交易': 'What is AI Quantitative Trading',
        '文本1': 'Financial quantitative trading is a method that emphasizes teaching and statistical analysis to help determine the value of financial assets (such as stocks, options, or cryptocurrencies). Quantitative traders use various data— including historical investment and stock market data—to develop trading algorithms and computer models. The information generated by these models helps investors analyze investment opportunities and create successful trading strategies. Typically, these strategies include specific information about entry and exit points, expected risks, and returns. The ultimate goal is to use quantifiable statistical data and indicators to help investors make profitable decisions.',
        '为什么要支付手续费': 'Why Pay Fees',
        '文本2': 'Since you need to open an account for staking investment in quantitative trading, the ETH charged is your account opening fee.',
        'AI量化交易的优势': 'Advantages of AI Quantitative Trading',
        '文本3_1': 'No Manual Intervention',
        '文本3_2': 'Reduces Cognitive Errors',
        '文本3_3': 'Doubles Effectiveness',
        '文本3_4': 'Allows Backtesting',
        '文本3_5': 'Can Implement Unlimited Strategies',
        '文本3_6': 'Automated Trading Frees Investor Time',
        '文本3_7': 'Consistency Between Execution and Backtesting',
        '文本3_8': 'System Follows Your Plan',
        '在哪里进行AI量化交易': 'Where to Perform AI Quantitative Trading',
        '文本4': 'Quantitative trading developed in collaboration with Coinbase, all programs run within Coinbase Wallet, making it an open and fair AI quantitative trading project.',
        '为什么会有这项目': 'Why This Project',
        '文本5': 'In 2022, partnered with Coinbase to create a new AI + financial ecosystem consensus circle. Jointly invested $2 billion to launch a quantitative trading platform, achieving perfect integration of AI and finance. The core product is an AI analysis computing chip, incorporated into our latest 6.2.1 AI robot. This innovation disrupts traditional industries, including financial trading. The platform simplifies trading. Moving forward, as a new generation of crypto asset trading platform, we will continue to develop and launch the Web 3.0 version.',
        '当前总质押': 'Total Staked',
        '当日收益': 'Daily Earnings',
        '总收益': 'Total Earnings',
    },

    inviteFriendsPage: {
        '邀请朋友': 'Invite Friends',
        '邀请朋友获得推荐奖励': 'Invite Friends to Earn Referral Rewards',
        '好友参与即可获得推荐奖励': 'Friends earn referral rewards by participating in AI arbitrage, renting mining machines',
        '您的邀请成功': 'Your Invitation is Successful',
        '全部': 'All',
        '直接': 'Direct',
        '间接': 'Indirect',
        '更多': 'More',
        '直接收益': 'Direct Earnings',
        '间接收益': 'Indirect Earnings',
        '无数据': 'No Data',
        '复制成功': 'Copy Successful',
    },

    coinDetailPage: {
        '交割合约': 'Contract',
        '最低价': 'Lowest Price',
        '成交量': 'Volume',
        '最高价': 'Highest Price',
        '成交额': 'Transaction Amount',
        '时间': 'Time',
        '更多': 'More',
        '委托订单': 'Order',
        '最新交易': 'Latest Trades',
        '暂无数据': 'No Data Available',
        '记录': 'Records',
        '买涨': 'Buy Long',
        '买跌': 'Sell Short',
        '交割': 'Settlement',
        '交割时间': 'Settlement Time',
        '投资金额': 'Investment Amount',
        '金额': 'Amount',
        '余额': 'Balance',
        '预期收益': 'Expected Return',
        '确认下单': 'Confirm Order',
        '请输入投资金额': 'Please Enter Investment Amount',
        '确认要下单吗': 'Confirm to Place Order?',
        '余额不足': 'Insufficient Balance',
    },

    contractsOrderPage: {
        '委托订单': 'Order',
        '等待中': 'Pending',
        '已結束': 'Completed',
        '无数据': 'No Data',
    },

    numKeyboard: {
        '完成': 'Done',
        '输入金额不能超过可用金额': 'Input amount cannot exceed available amount',
    },

    settingPage: {
        "设置": "Settings",
        "修改资金密码": "Change Fund Password",
        "旧密码": "Old Password",
        "请输入旧密码": "Please enter old password",
        "新密码": "New Password",
        "请输入新密码": "Please enter new password",
        "确认密码": "Confirm Password",
        "请再次输入新密码": "Please re-enter new password",
        "密码不能为空": "Password cannot be empty",
        "新密码和确认密码不匹配": "New password and confirm password do not match",
        "取消": "Cancel",
        "确认": "Confirm",
        "设置资金密码": "Set Fund Password",
        "资金密码": "Fund Password"
    },

    interfaceJs: {
        "获取失败": "Failed to retrieve",
        "登录失败": "Login failed",
        "设置成功": "Settings saved successfully",
        "修改成功": "Modification successful",
        "添加失败": "Addition failed",
        "修改失败": "Modification failed"
    },

    "时间": "Time",
    "数量": "Quantity",
    "状态": "Status",
    "审核中": "Under Review",
    "已完成": "Completed",
    "审核未通过": "Review Failed",
    "购买金额": "Purchase Amount",
    "全部": "All",
    "输入金额": "Enter Amount",
    "可用余额": "Available Balance",
    "大于最大可投": "Greater than Max Investment",
    "购买": "Purchase",
    "购买成功": "Purchase Successful",
    "请输入购买金额": "Please Enter Purchase Amount",
    "订单号": "Order Number",
    "购买日期": "Purchase Date",
    "结束日期": "End Date",
    "预计收益": "Expected Profit",
    "日收益率": "Daily Return Rate",
    "取消托管": "Cancel Custody",
    "接收金额": "Received Amount",
    "请填写金额": "Please Enter Amount",
    "上传充值凭证": "Upload Recharge Proof",
    "充值": "Recharge",
    "请上传图片": "Please Upload Image",
    "请输入金额": "Please Enter Amount",
    "确认": "Confirm",
    "产品": "Product",
    "购买数量": "Purchase Quantity",
    "购买方向": "Purchase Direction",
    "开仓时间": "Opening Time",
    "平仓时间": "Closing Time",
    "盈亏": "Profit and Loss",
    "取消": "Cancel",
    "质押数量": "Pledged Quantity",
    "期限": "Term",
    "开始时间": "Start Time",
    "结束时间": "End Time",
    "收益率": "Return Rate",
    "天": "Days",
    "质押金额必须大于0": "Pledge Amount Must Be Greater Than 0",
    "请选择质押产品": "Please Select Pledged Product",
    "选择质押的产品": "Select Pledged Product",
    "限额": "Limit",
    "周期": "Cycle",
    "预警收益": "Warning Profit",
    "数量限制": "Quantity Limit",
    "最少可投": "Minimum Investable",
    "最大可投": "Maximum Investable",
    "立即租用": "Rent Now",
    "详情": "Details",
    "收益金额": "Income Amount",

    "身份认证": "Identity Authentication",
    "个人身份认证": "Personal Identity Authentication",
    "完成认证后可获得的权限": "Permissions available after completion",
    "权限一": "Increase withdrawal limit, 500 BTC limit per 24 hours",
    "权限二": "Increase fiat trading limit, 1,000,000 USDT per transaction",
    "去认证": "Go to Authentication",
    "已认证": "Certified",
    "证件类型": "Document Type",
    "身份证": "ID Card",
    "姓名": "Name",
    "证件号码": "Document Number",
    "上传证件正面": "Upload Front of Document",
    "正面证件照": "Front Document Photo",
    "上传证件反面": "Upload Back of Document",
    "反面证件照": "Back Document Photo",
    "确定": "Confirm",
    "请输入姓名": "Please enter your name",
    "请输入证件号码": "Please enter your document number",
    "请上传证件": "Please upload the document",
    "查看认证信息": "View certification information",
}